import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

import { useAppSelector } from 'store';
import { Helpers } from 'utils';
import { GiftIcon } from 'app/icons';
import { Badge } from '../Badge';
import { PictureFrame } from '../PictureFrame';
import PhoneFrame from './images/phone-frame.svg';
import { ReactComponent as GiftedLogo } from './images/gifted-logo.svg';
import { ReactComponent as PhoneNotch } from './images/phone-notch.svg';
import { ReactComponent as PhoneStatus } from './images/phone-status.svg';
import { ReactComponent as PhoneNav } from './images/phone-nav.svg';
import { ReactComponent as BtnPass } from './images/btn-pass.svg';
import { ReactComponent as BtnWave } from './images/btn-wave.svg';

interface Props {
    name: string;
    media?: string;
    price?: number|null;
    type?: GiftType;
    image?: string | null;
}

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;

    svg {
        &:first-child {
            margin-top: -1px;
        }

        &:last-child {
            position: absolute;
            top: -5px;
            right: 0;
            left: 0;
            width: 100%;
            z-index: 10;
        }
    }
`;

const StyledGiftIcon = styled(GiftIcon)`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: auto;
    transform: translate(-50%, -50%);

    path {
        fill: var(--text-alt);
    }
`;

const CardLogo = styled(PictureFrame)`
    position: absolute;
    left: 12px;
    top: 12px;
`;

const CardTitle = styled(Badge)`
    font-size: 8px;
    font-weight: 800;
    position: absolute;
    bottom: 12px;
    left: 12px;
    background-color: var(--text-alt);
    color: var(--grey-9);
    cursor: default;
    white-space: break-spaces;
    max-width: 60%;

    &:empty {
        display: none;
    }
`;

const CardPrice = styled(Badge)`
    font-size: 10px;
    font-weight: 900;
    position: absolute;
    bottom: 12px;
    right: 12px;
    background-color: #73d7bf;
    color: white;
    cursor: default;
    white-space: pre-wrap;
    max-width: 60%;

    &:empty {
        display: none;
    }
`

const CardHeader = styled(motion.div) <{
    $image?: string | null;
}>`
    flex: 1 1 auto;
    position: relative;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    background-color: var(--blue);
    user-select: none;
    overflow: hidden;
    
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        
        + ${StyledGiftIcon} {
            display: none;
        }
    }

    ${(props) => props.$image && `
        background-image: url(${props.$image});
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;

        ${StyledGiftIcon} {
            display: none;
        }
    `}
`;

const CardFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    padding: 8px;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    background-color: #ffffff;

    svg {
        width: 92px;
    }
`;

const Card = styled.div`
    flex: 0 0 470px;
    display: flex;
    flex-direction: column;
    position: relative;
    // width: 100%;
    max-height: 470px;
    width: 264px;
    border-radius: 24px;
    box-shadow: var(--shadow-1);
    background-color: var(--blue);

    &:before,
    &:after {
        content: '';
        position: absolute;
        bottom: -12px;
        width: 120px;
        height: 12px;
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
        background: red;
    }

    &:before {
        width: 200px;
        left: 32px;
        background-color: var(--grey-3);
    }

    &:after {
        bottom: -24px;
        left: 47px;
        width: 166px;
        background-color: var(--grey-4);
    }
`;

const DynamicBackground = styled(motion.div) <{
    $image?: string | null;
}>`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(var(--blue), var(--grey-1));
    filter: blur(25px) saturate(180%);
    animation: rotate 60s infinite linear;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(38, 33, 97, 0.15);
    }

    ${(props) => props.$image && `
        background-image: url(${props.$image}),
            linear-gradient(var(--blue), var(--grey-1));
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
    `}
    
    @keyframes rotate {
        from {
            transform: scale(2.15) rotate(0deg);
        }

        to {
            transform: scale(2.15) rotate(360deg);
        }
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 9px;
    z-index: 2;
`;

const Footer = styled.div`
    height: 72px;
    margin-top: auto;
    z-index: 2;
    
    svg {
        height: 100%;
        width: 100%;
    }
`;

const Viewport = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    position: relative;
    width: 100%;
    height: calc(100% + 1.25px);
    border-radius: 34px;
    background-color: #262161;
    overflow: hidden;
`;

const Container = styled(motion.div)`
    width: 347px;
    height: 706px;
    padding: 20px;
    background-image: url(${PhoneFrame});
    position: relative;
    margin-top: 24px;
`;

const SwipeBtn = styled.div`
    position: relative;
    background: rgb(38, 33, 97);
    height: 38px;
    width: 92px;
    border-radius: 13px;
    display: flex;
    align-items: center;
`

const SwipeBtnText = styled.div`
    color: white;
    padding-left: 40px;
    font-weight: bold;
    font-size: 13px;
`

const CreatorsHeader = styled.h3`
    position: absolute;
    z-index: 10;
    top: -45px;
    left: -5px;
    text-align: center;
    width: 100%;
    color: var(--blue);
`


export function MobilePreview({
    name,
    media,
    price,
    type,
    image
}: Props) {
    const containerAnimation = {
        initial: { opacity: 0, rotateY: '180deg', scale: 0.85 },
        animate: { opacity: 1, rotateY: '0deg', scale: 1 },
        exit: { x: '100%' },
        transition: {
            type: 'spring',
            bounce: 0,
            duration: 0.8,
            delay: 0.1
        }
    };

    const imageAnimation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 }
    };

    const { brand } = useAppSelector((state) => state.profile);

    const mediaExtension = media?.split('.').pop();

    const {
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    return (
        <AnimatePresence>
            <Container {...containerAnimation}>
            <CreatorsHeader>What creators will see</CreatorsHeader>

                <Viewport>
                    <Header>
                        <PhoneNotch />

                        <PhoneStatus />
                    </Header>

                    <Body>
                        <GiftedLogo />

                        <Card>
                            <CardHeader
                                {...imageAnimation}
                                key={image}
                                $image={image}
                            >
                                {
                                    mediaExtension && settings && Helpers.isVideo(mediaExtension, settings) && (
                                        <video
                                            autoPlay={true}
                                            loop={true}
                                            muted={true}
                                        >
                                            <source src={media} />
                                        </video>
                                    )
                                }

                                <StyledGiftIcon />

                                <CardLogo
                                    image={brand?.logo}
                                    width={54}
                                    height={54}
                                    isGift={true}
                                />

                                <CardTitle>
                                    {name}
                                </CardTitle>


                                {price && (
                                    <CardPrice>
                                        ${price}
                                    </CardPrice>
                                )}

                                {!price && type && (
                                    <CardPrice>
                                        {type}
                                    </CardPrice>
                                )}


                            </CardHeader>

                            <CardFooter>
                                <SwipeBtn>
                                    <BtnPass />
                                    <SwipeBtnText>Pass</SwipeBtnText>
                                </SwipeBtn>

                                <SwipeBtn>
                                    <BtnWave />
                                    <SwipeBtnText>Apply</SwipeBtnText>
                                </SwipeBtn>


                            </CardFooter>
                        </Card>
                    </Body>

                    <Footer>
                        <PhoneNav />
                    </Footer>

                    <DynamicBackground
                        {...imageAnimation}
                        key={image}
                        $image={image}
                    />
                </Viewport>
            </Container>
        </AnimatePresence>
    );
}
