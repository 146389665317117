import {batch} from 'react-redux';

import {API} from 'api';
import {Helpers} from 'utils';
import {Constants} from '../constants';
import {appActions} from './appActions';
import settingsService from "../../services/settingsService";
import {Actions} from "./index";
import AnalyticsEvents from 'services/analyticsEvents';
import errorReporter from 'services/errorReporter';
import ReactGA from 'react-ga4';

let timeoutHandler: NodeJS.Timeout;

const _setStatus = (status: string) => ({
    type: Constants.Profile.Status,
    payload: status
});

const _setLoading = (loading: boolean) => ({
    type: Constants.Profile.Loading,
    payload: loading
});

const _setLoadingBilling = (loading: boolean) => ({
    type: Constants.Profile.LoadingBilling,
    payload: loading
});

const _setLoadingInvoices = (loading: boolean) => ({
    type: Constants.Profile.LoadingInvoices,
    payload: loading
});

const _setLoadingNotifications = (loading: boolean) => ({
    type: Constants.Profile.LoadingNotifications,
    payload: loading
});

const _setLoadingSubscription = (loading: boolean) => ({
    type: Constants.Profile.LoadingSubscription,
    payload: loading
});

const cancelBrandSubscription = (
    callback?: (subscription?: BrandSubscription | null) => void
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('cancel-brand-subscription'));
        });

        try {
            const { profile: { brand } } = getState();

            if (brand && brand.uid) {
                await API.Profile.cancelBrandSubscription(brand.uid);

                batch(() => {
                    dispatch({
                        type: Constants.Profile.Brand,
                        payload: {
                            ...brand,
                            subscription: {
                                ...brand.subscription,
                                // added to make sure the UI is updated
                                // will be replaced by the actual data
                                // upon refresh
                                canceled_at: new Date()
                            }
                        }
                    });

                    dispatch(_setStatus('success'));
                    dispatch(_setLoading(false));
                });

                if (callback) {
                    callback(brand.subscription);
                }
            }
        } catch (err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const pauseSubscription = (subscription: BrandSubscription) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('pause-brand-subscription'));
        });

        try {
            const { profile: { brand } } = getState();

            if (brand && brand.uid) {
                await API.Profile.pauseBrandSubscription(brand.uid);

                batch(() => {
                    dispatch({
                        type: Constants.Profile.Brand,
                        payload: {
                            ...brand,
                            subscription: {
                                ...brand.subscription,
                                paused_at: new Date()
                                // status: 'paused',
                            }
                        }
                    });

                    dispatch(_setStatus('success'));
                    window.location.reload()
                    dispatch(_setLoading(false));
                });
            }
        } catch (err) {
            batch(() => {
                alert('Error: Subscription could not be paused, contact us.')
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }

}

const resumeSubscription = (subscription: BrandSubscription) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('pause-brand-subscription'));
        });

        try {
            const { profile: { brand } } = getState();

            if (brand && brand.uid) {
                await API.Profile.resumeBrandSubscription(brand.uid);

                batch(() => {
                    dispatch({
                        type: Constants.Profile.Brand,
                        payload: {
                            ...brand,
                            subscription: {
                                ...brand.subscription,
                                status: 'active',
                            }
                        }
                    });

                    dispatch(_setStatus('success'));
                    dispatch(_setLoading(false));
                    window.location.reload();
                });
            }
        } catch (err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }

}


const completeSignUp = () => {
    return (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        const {
            auth: { signup: { user } },
        } = getState();

        localStorage.setItem('teamInviteFinish', 'true');

        if (user) {
            batch(() => {
                dispatch({
                    type: Constants.Auth.SignUp,
                    payload: { step: 4, user: {...user, status: 'ACTIVE'} }
                });

                Actions.Auth._handleAuth({...user, status: 'ACTIVE'}, dispatch).catch((error: any) => {throw error});
            });
        }
    }
}

const createBrandProfile = (form: BrandForm) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('create-brand-profile'));
        });

        try {
            const {
                auth: { signup: { user } }
            } = getState();

            if (user && user.brand_representative_id) {
                const data = {
                    ...form,
                    tags: form.tags.map((tag) => tag.id)
                };
                const result = await API.Profile.createBrandProfile(
                    user.brand_representative_id,
                    data
                );

                await batch(async () => {
                    dispatch({
                        type: Constants.Profile.Brand,
                        payload: { ...result, tags: form.tags }
                    });

                    dispatch({
                        type: Constants.Auth.SignUp,
                        payload: {
                            step: 2,
                            user: {
                                ...user,
                                brand_id: result.uid
                            }
                        }
                    });

                    await settingsService.loadSettings(result.uid);
                    dispatch(_setStatus('success'));
                    appActions.setAlert(null)(dispatch)
                    dispatch(_setLoading(false));
                });
            }
        } catch (err) {
            batch(() => {
                appActions.setAlert(Helpers.createRequestError('Creating brand profile', err))(dispatch);

                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const createBrandProfileSubscription = (
    form: SubscriptionForm,
    callback?: () => void
) => {
    return (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        const { profile: { brand } } = getState();

        if (brand) {
            dispatch({
                type: Constants.Profile.Subscription,
                payload: form
            });

            if (callback) {
                callback();
            }
        }
    }
}

const getBrandProfile = (id: string) => {
    return async (dispatch: AppDispatch) => {
        clearTimeout(timeoutHandler);

        dispatch(_setLoading(true));

        try {
            const result = await API.Profile.getBrandProfile(id);

            batch(() => {
                dispatch({
                    type: Constants.Profile.Brand,
                    payload: result
                });

                dispatch(_setLoading(false));
            });
        } catch (err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });

            timeoutHandler = setTimeout(() => {
                dispatch(_setStatus('idle'));
            }, settingsService.alertTimeout);
        }
    }
}

const getBrandTeam = (id: string) => {
    return async (dispatch: AppDispatch) => {
        clearTimeout(timeoutHandler);

        dispatch(_setLoading(true));

        try {
            const result = await API.Profile.getBrandTeam(id);

            batch(() => {
                dispatch({
                    type: Constants.Profile.Team,
                    payload: result
                });

                dispatch(_setLoading(false));
            });
        } catch (err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });

            timeoutHandler = setTimeout(() => {
                dispatch(_setStatus('idle'));
            }, settingsService.alertTimeout);
        }
    }
}


const getBrandProfileBilling = () => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        dispatch(_setLoadingBilling(true));

        try {
            const { profile: { brand } } = getState();

            if (brand && brand.subscription) {
                const result = await API.Profile.getBrandProfileBilling(brand.uid);

                batch(() => {
                    dispatch({
                        type: Constants.Profile.Billing,
                        payload: result
                    });

                    dispatch(_setLoadingBilling(false));
                });
            }
        } catch {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoadingBilling(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const getInvoices = () => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        dispatch(_setLoadingInvoices(true));

        try {
            const { profile: { brand } } = getState();

            if (brand && brand.subscription) {
                const result = await API.Profile.getInvoices(brand.uid);

                batch(() => {
                    dispatch({
                        type: Constants.Profile.Invoices,
                        payload: result
                    });

                    dispatch(_setLoadingInvoices(false));
                });
            }
        } catch {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoadingInvoices(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const getNotifications = () => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        dispatch(_setLoadingNotifications(true));

        try {
            const { profile: { brand } } = getState();

            if (brand) {
                const { data } = await API.Profile.getNotifications(brand.uid);

                batch(() => {
                    dispatch({
                        type: Constants.Profile.Notifications,
                        payload: data
                    });

                    dispatch(_setLoadingNotifications(false));
                });
            }
        } catch {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoadingNotifications(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const getProfiles = (
    representativeId: string,
    brandId: string,
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(_setLoading(true));
        try {
            const [
                representativeProfile,
                brandProfile
            ] = await API.Profile.getProfiles(representativeId, brandId);


            batch(() => {
                dispatch({
                    type: Constants.Profile.Brand,
                    payload: brandProfile
                });

                dispatch({
                    type: Constants.Profile.Representative,
                    payload: representativeProfile
                });

                settingsService.loadSettings(brandProfile.uid).then((settings) => {
                    new AnalyticsEvents(settings).login(representativeProfile, brandProfile);
                }).catch((error) => {
                    errorReporter.report('Failed to load settings',error);
                });

                dispatch(_setLoading(false));
            });
        } catch {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });

            timeoutHandler = setTimeout(() => {
                dispatch(_setStatus('idle'));
            }, settingsService.alertTimeout);
        }
    }
}

const getRepresentativeProfile = (id: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(_setLoading(true));

        try {
            const result = await API.Profile.getRepresentativeProfile(id);

            batch(() => {
                dispatch({
                    type: Constants.Profile.Representative,
                    payload: result
                });

                dispatch(_setLoading(false));
            });
        } catch {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });

            timeoutHandler = setTimeout(() => {
                dispatch(_setStatus('idle'));
            }, settingsService.alertTimeout);
        }
    }
}

const setBrandProfile = (
    form: BrandForm,
    callback?: () => void
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('set-brand-profile'));
        })

        try {
            const { profile: { brand }, auth: { signup: { user } }
        } = getState();

            if (brand && user?.status === 'INCOMPLETE' && !brand.social_profiles) {
                dispatch({
                    type: Constants.Auth.SignUp,
                    payload: {
                        step: 2,
                        user: {
                            ...user,
                            brand_id: brand.uid
                        }
                    }
                });
                dispatch(_setLoading(false));
                dispatch(_setStatus('success'));
                return
            }

            if (brand) {
                const data = {
                    ...form,
                    tags: form.tags.map((tag) => tag.id),
                };

                await API.Profile.setBrandProfile(brand.uid, data);

                batch(() => {
                    dispatch({
                        type: Constants.Profile.Brand,
                        payload: {
                            ...brand,
                            ...form,
                        }
                    });

                    if (user?.status === 'INCOMPLETE') {
                        dispatch({
                            type: Constants.Auth.SignUp,
                            payload: {
                                step: 2,
                                user: {
                                    ...user,
                                    brand_id: brand.uid
                                }
                            }
                        });
                    }

                    dispatch(_setStatus('success'));
                    appActions.setAlert(null)(dispatch)
                    dispatch(_setLoading(false));
                });

                if (callback) {
                    callback();
                }
            }
        } catch (err) {
            batch(() => {
                appActions.setAlert(Helpers.createRequestError('Updating brand', err))(dispatch);

                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setBrandProfileLogo = (file: File) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('set-brand-logo'));
        });

        try {

            const { profile: { brand } } = getState();

            if (brand) {
                const { logo } = await API.Profile.setBrandProfileLogo(
                    brand.uid,
                    file
                );

                batch(() => {
                    dispatch({
                        type: Constants.Profile.Brand,
                        payload: { ...brand, logo }
                    });

                    dispatch(_setStatus('success'));
                    dispatch(_setLoading(false));
                });
            }
        } catch(err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));

                appActions.setAlert(Helpers.createRequestError('Updating logo', err))(dispatch);

            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setBrandProfileSocial = (
    form: SocialProfileForm,
    incrementStep = true,
    callback = () => {}
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('set-brand-social'));
        });

        try {
            const {
                auth: { signup: { user } },
                profile: { brand }
            } = getState();

            if (brand) {
                const data = {
                    social_profiles: form.social_profiles
                };
                await API.Profile.setBrandProfileSocial(brand.uid, data);

                batch(() => {
                    dispatch({
                        type: Constants.Profile.Brand,
                        payload: {
                            ...brand,
                            social_profiles: form.social_profiles
                        }
                    });

                    dispatch(_setStatus('success'));
                    dispatch(_setLoading(false));
                });

                if (incrementStep) {
                    dispatch({
                        type: Constants.Auth.SignUp,
                        payload: { step: 3, user: user }
                    });
                } else {
                    dispatch({
                        type: Constants.Auth.SignUp,
                        payload: { user: user}
                    })
                    if (callback) {
                        callback()
                    }
                }
            }
        } catch (err) {
            console.log('err', err)
            batch(() => {
                appActions.setAlert(Helpers.createRequestError('Updating brand social accounts', err))(dispatch);

                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setBrandProfileSubscription = (
    form: SubscriptionForm,
    callback?: () => void
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoadingSubscription(true));
            dispatch(_setStatus('set-brand-subscription'));
        });

        console.log('Are we doing this?')

        try {
            const {
                profile: {
                    brand,
                }
            } = getState();

            if (brand) {
                await API.Profile.setBrandProfileSubscription(
                    brand.uid,
                    form
                );                  

                batch(() => {
                    API.Profile.getBrandProfile(brand.uid)
                      .then((brandProfile) => {
                        dispatch({
                          type: Constants.Profile.Brand,
                          payload: {
                            ...brandProfile
                          }
                        });

                        console.log('brandProfile', brandProfile)

                        /////// return to here

                        ReactGA.event({
                            action: "purchase",
                            // @ts-ignore
                            transaction_id: brand?.subscriptionv2?.stripe_subscription_id,
                            label: "Brand subscribed", // optional
                            value: brand?.subscriptionv2?.subscription?.amount,
                            // @ts-ignore
                            currency: 'AUD',
                            items: [
                                {
                                    item_id: brand?.subscriptionv2?.stripe_subscription_id,
                                    item_name: brand?.subscriptionv2?.subscription?.name,
                                    affiliation: 'HashGifted',
                                    price: brand?.subscriptionv2?.subscription?.amount,
                                    quantity: 1,
                                }
                            ]
                        });        
                
                        dispatch(_setStatus('success'));
                        dispatch(_setLoadingSubscription(false));

                        if (callback) {
                          callback();
                        }
                      })
                      .catch((error) => {
                          throw error
                      });
                  });

            }
        } catch {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoadingSubscription(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setNotificationRead = (
    id: string,
    callback?: () => void
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        try {
            const { profile: { brand, notifications } } = getState();

            if (brand) {
                if (callback) {
                    callback();
                }

                await API.Profile.setNotificationRead(brand.uid, id);

                dispatch({
                    type: Constants.Profile.Notifications,
                    payload: notifications.map((notification) =>
                        notification.uid === id ?
                            { ...notification, read_at: new Date() } :
                            notification
                    )
                });
            }
        } catch (err) {
            console.error('setNotificationRead', err);
        }
    }
}

const setRepresentativeProfile = (
    form: RepresentativeForm,
    incrementStep = true,
    callback?: () => void
) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('set-representative-profile'));
        });

        try {
            const {
                auth: { signup: { user } },
                profile: { representative }
            } = getState();

            if (representative) {
                await API.Profile.setRepresentativeProfile(
                    representative.uid,
                    form
                );

                if (incrementStep) {
                    // make sure to get the values of brand
                    // area of expertise dropdown
                    // and available subscriptions
                    // before going to the next step
                    await dispatch(appActions.getPublicSharedInfo());
                }

                batch(() => {
                    dispatch({
                        type: Constants.Profile.Representative,
                        payload: { ...representative, ...form }
                    });

                    if (incrementStep) {
                        dispatch({
                            type: Constants.Auth.SignUp,
                            payload: {
                                step: 4,
                                user: { ...user }//, status: 'ACTIVE' }
                            }
                        });
                    }

                    dispatch(_setStatus('success'));
                    dispatch(_setLoading(false));
                    if (callback) {
                        setTimeout(() => {
                            callback()
                        }, 1000);
                    }
                });
            }
        } catch (err) {
            batch(() => {
                appActions.setAlert(Helpers.createRequestError('Updating profile', err))(dispatch);

                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setRepresentativeProfileImage = (file: File) => {
    return async (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        clearTimeout(timeoutHandler);

        batch(() => {
            dispatch(_setLoading(true));
            dispatch(_setStatus('set-representative-image'));
        });

        try {
            const { profile: { representative } } = getState();

            if (representative) {
                const { headshot_image } = await API.Profile.setRepresentativeProfileImage(
                    representative.uid,
                    file
                );

                batch(() => {
                    dispatch({
                        type: Constants.Profile.Representative,
                        payload: { ...representative, headshot_image }
                    });

                    dispatch(_setStatus('success'));
                    dispatch(_setLoading(false));
                });
            }
        } catch(err) {
            batch(() => {
                dispatch(_setStatus('error'));
                dispatch(_setLoading(false));

                appActions.setAlert(Helpers.createRequestError('Updating profile', err))(dispatch);
            });
        }

        timeoutHandler = setTimeout(() => {
            dispatch(_setStatus('idle'));
        }, settingsService.alertTimeout);
    }
}

const setShopifyConnection = (data: any) => {
    return (
        dispatch: AppDispatch,
        getState: () => AppReducer
    ) => {
        const {
            profile: { brand }
        } = getState();

        if (brand) {
            dispatch({
                type: Constants.Profile.Shopify,
                payload: data
            });
        }
    }
}

export const profileActions = {
    cancelBrandSubscription,
    pauseSubscription,
    resumeSubscription,
    completeSignUp,
    createBrandProfile,
    createBrandProfileSubscription,
    getBrandProfile,
    getBrandTeam,
    getBrandProfileBilling,
    getInvoices,
    getNotifications,
    getProfiles,
    getRepresentativeProfile,
    setBrandProfile,
    setBrandProfileLogo,
    setBrandProfileSocial,
    setBrandProfileSubscription,
    setNotificationRead,
    setRepresentativeProfile,
    setRepresentativeProfileImage,
    setShopifyConnection
};
