import {useEffect, useMemo, useState} from 'react';
import {Actions, useAppDispatch, useAppSelector} from 'store';
import styled from 'styled-components';
import {SubscriptionModalContent} from './SubscriptionModalContent';
import {SubscriptionFlowPageType} from "../../../services/subscriptionService";
import ReactConfetti from "react-confetti";
import AnalyticsEvents from "services/analyticsEvents";
import {ClientSettings} from "../../../services/settingsService";
import ReactGA from 'react-ga4';

interface Props {
    showAllPlans: boolean;
}

enum WaveStatus {
    SUBMITTED = 'SUBMITTED',
    SHORTLISTED = 'SHORTLISTED',
    NEGOTIATION = 'NEGOTIATION',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    COMPLETED = 'COMPLETED',
}

const Container = styled.div`
    position: absolute;
    top: 0; left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10000000;
    backdrop-filter: blur(2px);
    background: rgba(0,0,0,.4);
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalContainer = styled.div`
    width: 1011px;
    height: 742px;
    background: white;
    box-shadow: 0px 6px 12px rgba(41, 43, 50, 0.12);
    border-radius: 32px;

    @media (max-width:900px) and (min-width:0px) {
        width: 100%;
    }
`

export function SubscriptionFlowModal({showAllPlans}: Props) {

    const dispatcher = useAppDispatch();

    const {
        global: { subscriptions, settings },
        profile: { brand },
        wave,
        gift
    } = useAppSelector((state) => state);

    const analyticsEvents = useMemo(() => new AnalyticsEvents(settings as ClientSettings), [settings]);

    useEffect(() => {
        const fetchBillingData = () => {
            dispatcher(Actions.Profile.getBrandProfileBilling())
            .then(() => {
                return
            })
            .catch((error: any) => {
                throw error
            });
        };

        fetchBillingData();
    }, [])
    

    const [showConfetti, setShowConfetti] = useState(false);

    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (settings && brand) {
            dispatcher(Actions.Profile.getBrandProfile(brand?.uid))
                .catch((error: any) => {
                    throw error
                });
        }
    }, [settings, refresh, dispatcher])


    useEffect(() => {
        if (brand && showConfetti) {
            ReactGA.event({
                category: "custom event",
                // @ts-ignore
                transaction_id: brand?.subscriptionv2?.stripe_subscription_id,
                action: "purchase",
                label: "Brand subscribed", // optional
                value: brand?.subscriptionv2?.subscription?.amount,
                // @ts-ignore
                currency: 'AUD',
                items: [
                    {
                        item_id: brand?.subscriptionv2?.stripe_subscription_id,
                        item_name: brand?.subscriptionv2?.subscription?.name,
                        affiliation: 'HashGifted',
                        price: brand?.subscriptionv2?.subscription?.amount,
                        quantity: 1,
                    }
                ]
            });        
        }
    }, [showConfetti, brand])


    const onSuccessfullySubscribed = () => {
        console.log('Are we doing this one?', brand)

        ReactGA.event({
            // @ts-ignore
            transaction_id: brand?.subscriptionv2?.stripe_subscription_id,
            action: "purchase",
            label: "Brand subscribed", // optional
            value: brand?.subscriptionv2?.subscription?.amount,
            // @ts-ignore
            currency: 'AUD',
            items: [
                {
                    item_id: brand?.subscriptionv2?.stripe_subscription_id,
                    item_name: brand?.subscriptionv2?.subscription?.name,
                    affiliation: 'HashGifted',
                    price: brand?.subscriptionv2?.subscription?.amount,
                    quantity: 1,
                }
            ]
        });        

        if (brand?.uid) {
            dispatcher(Actions.Profile.getBrandProfile(brand?.uid)).then(() => {
                analyticsEvents.subscriptionPlansSubscribed(brand)

                console.log('brand', brand)
        
                /////// return to here
                ReactGA.event({
                    // @ts-ignore
                    transaction_id: brand?.subscriptionv2?.stripe_subscription_id,
                    action: "purchase",
                    label: "Brand subscribed", // optional
                    value: brand?.subscriptionv2?.subscription?.amount,
                    // @ts-ignore
                    currency: 'AUD',
                    items: [
                        {
                            item_id: brand?.subscriptionv2?.stripe_subscription_id,
                            item_name: brand?.subscriptionv2?.subscription?.name,
                            affiliation: 'HashGifted',
                            price: brand?.subscriptionv2?.subscription?.amount,
                            quantity: 1,
                        }
                    ]
                });        
        
            })
                .catch((error: any) => {
                    throw error;
                });
        


            if (wave && wave?.list && gift?.item?.uid) {
                dispatcher(Actions.Wave.getByStatuses(
                    gift.item.uid,
                    [WaveStatus.SUBMITTED, WaveStatus.NEGOTIATION],
                    { page: 1 },
                    'created_at' as string,
                    'all',
                    '',
                )).catch((error: any) => {throw error});    

                dispatcher(Actions.Gift.getGift(gift.item.uid)).catch((error: any) => {throw error});
            }

        }

        dispatcher(Actions.Profile.getBrandProfileBilling()).then(() => {return})
            .catch((error: any) => {
                throw error;
            });

        setRefresh(true);
    

        setTimeout(() => {
            dispatcher(Actions.App.setShowPaymentModal(false));
        }, 9000);
    };

    const onPageChanged = (page: SubscriptionFlowPageType) => {
        if (page !== SubscriptionFlowPageType.SUCCESS) {
            return;
        }
        setShowConfetti(true);
        onSuccessfullySubscribed();
    }

    return (
        <Container style={{background: showConfetti ? 'none' : 'rgba(0,0,0,.4)', backdropFilter: showConfetti ? 'none' : 'blur(2px)', pointerEvents: showConfetti ? 'none' : 'auto'}}>
            {showConfetti && (
                <ReactConfetti recycle={false} numberOfPieces={1000}/>
            )}
            <ModalContainer style={{display: showConfetti ? 'none' : 'flex'}}>
                <SubscriptionModalContent subscriptions={subscriptions} brand={brand as BrandProfile} onPageChanged={onPageChanged} onCompletedCta={onSuccessfullySubscribed} showAllPlans={showAllPlans} />
            </ModalContainer>
        </Container>
    );
}
