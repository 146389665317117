import {PropsWithChildren, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

import {Actions, useAppDispatch, useAppSelector} from 'store';
import {Alert, InfluencerCard, NoResult, ReviewModal} from 'app/components';
import {CompletedContentList} from '../CompletedContentList';
import {InfluencerPosts} from '../InfluencerPosts';
import {InfluencerInfo} from '../InfluencerInfo';
import {InfluencerPostInfo} from '../InfluencerPostInfo';
import {API} from "../../../../api";
import subscriptionService, {PlanType} from "../../../../services/subscriptionService";
import UpgradeButton from "../../../components/UpgradeButton";
import AnalyticsEvents from "services/analyticsEvents";
import {ClientSettings} from "../../../../services/settingsService";
import { Id } from 'react-beautiful-dnd';
import ReactGA from 'react-ga4';

interface Props {
    giftId: string;
    paramsStatus: string;
}

const Body = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 28px;
    row-gap: 28px;
    margin-right: -28px;
    margin-left: -28px;
`;

const LeftContent = styled.div`
    flex: 1;
    grid-area: left-content;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    height: 100%;
    padding: 24px 52px 40px;
    overflow: hidden overlay;
`;

const RightContent = styled.div`
    grid-area: right-content;
    display: flex;
    flex-direction: column;
    position: relative;
    border-left: 1px solid var(--grey-4);
    height: 100%;
`;

const HelpArea = styled.div`
  height: 100%;
  display: flex;
`

const LinkDescription = styled.div`
    padding-top: 18px;
    font-size: 14px;
    padding-bottom: 0px;
    font-weight: 600;
    a {
      text-decoration: underline;
      text-underline-position: under;
      text-underline-offset: 1px;
      color: var(--grey-6);
    }
`

type ModalState = { show: boolean; influencer: Influencer | undefined, completed: boolean };

export function CompletedContent({
    giftId,
    paramsStatus,
    children
}: PropsWithChildren<Props>) {
    const navigate = useNavigate();

    const dispatcher = useAppDispatch();

    const {
        completed: {
            list,
            view,
            loading,
            loadingView,
            loadingReviews
        },
        gift: { item },
        profile: { brand },
        global: { settings }
    } = useAppSelector((state) => state);

    const [selectedInfluencerContent, setSelectedInfluencerContent] = useState<SocialMediaContent | undefined>();
    const [selectedInfluencer, setSelectedInfluencer] = useState<{
        influencer: Influencer,
        insights: Analytics,
        attribute?: AttributeValue[],
        tags?: IdName[]
    }|null>(null);

    const [viewProfile, setViewProfile] = useState<boolean>(false);

    const [, setViewReviews] = useState<boolean>(false);

    const [selectedPost, setSelectedPost] = useState<SocialMedia>();

    const [modalState, setModalState] = useState<ModalState>({ show: false, influencer: undefined, completed: false });

    const showList = useMemo(() => {
        return ['active', 'past'].includes(paramsStatus);
    }, [paramsStatus]);

    useEffect(() => {
        if (showList) {
            dispatcher(Actions.Completed.getContent(giftId)).catch((error: any) => {throw error});
        }

        return () => {
            dispatcher(Actions.Completed.clearState());
        }
    }, [giftId, dispatcher, showList]);

    useEffect(() => {
        if (selectedInfluencerContent?.influencer.uid === selectedInfluencer?.influencer.uid) {
            return;
        } else if (selectedInfluencerContent?.influencer.uid) {
            API.Influencer.getInfluencer(selectedInfluencerContent?.influencer.uid)
                .then((influencer) => {
                    setSelectedInfluencer(influencer);
                }).catch((error: any) => {throw error});
        } else {
            setSelectedInfluencer(null);
        }
    }, [selectedInfluencerContent]);

    const handleGoBack = () => {
        setSelectedInfluencerContent(undefined);
        setViewProfile(false);
        setSelectedPost(undefined);
        dispatcher(Actions.Completed.clearState());
        dispatcher(Actions.Completed.getContent(giftId)).catch((error: any) => {throw error});
    }

    const handleOnClick = (content: SocialMediaContent) => {
        setSelectedInfluencerContent(content);
        setViewProfile(false);
        setSelectedPost(undefined);
        dispatcher(Actions.Completed.getInfluencerData(
            giftId,
            content.influencer.uid
        )).catch((reason) => {
            console.warn('social media get influencer data failed', reason);
        })
    }

    const handleSubmitReview = (form: ReviewForm) => {
        setModalState((prev) => ({ ...prev, loading: true }));

        if (selectedInfluencerContent && !modalState.completed) {
            dispatcher(Actions.Wave.submitReview(giftId, selectedInfluencerContent.influencer.uid, form, () => {
                setModalState((prev) => ({
                    ...prev,
                    completed: true,
                    loading: false
                }));

                const analyticsEvents = new AnalyticsEvents(settings as ClientSettings);
                if (item && brand && modalState.influencer) {
                    analyticsEvents.giftInfluencerReviewed(
                        item,
                        brand,
                        modalState.influencer,
                        form
                    );
                }
            })).catch((error: any) => {throw error});
        } else {
            setModalState((prev) => ({
                ...prev,
                influencer: undefined,
                completed: false,
                show: false,
            }))
        }
    }

    const handleViewProfile = (influencer: Influencer) => {
        ReactGA.event({
            category: "custom event",
            action: "view_influencer_profile",
            label: "Brand viewed influencer profile", // optional
        });        

        setViewProfile(true)
        dispatcher(Actions.Completed.getInfluencerReviews(influencer.uid)).catch((error: any) => {throw error});
    }

    const handleViewReviews = (influencer: Influencer) => {
        setViewReviews(true);
        dispatcher(Actions.Completed.getInfluencerReviews(influencer.uid)).catch((error: any) => {throw error});
    }

    const tagsSentence = useMemo(() => {

        if (!brand) {
            return null;
        }
        return 'When accepted creators on Instagram, TikTok or YouTube posts and tags content with ' + brand.socialTags.join(', ') + ', it will appear in Completed Content.';
    }, [brand]);

    const renderEmpty = () => {
        return (
            <NoResult
                image={'camera'}
                title={"We couldn't find any posts just yet"}
            />
        );
    }

    const proPlan = useMemo(() => subscriptionService.findPlanDefinition(PlanType.PRO), []);
    const currentPlan = useMemo(() => brand?.subscription ? subscriptionService.findPlanTypeForSubscription(brand?.subscription) : null, [brand]);
    const showSocialsWarning = useMemo(() => !brand?.social_profiles || brand?.social_profiles.length === 0, [brand])
    const showContentWarning = useMemo(() => (!currentPlan || currentPlan !== PlanType.PRO) && brand, [currentPlan, brand]);

    return (
        <>
            <LeftContent>
                <Body>

                    {!showSocialsWarning && showContentWarning && brand && (
                        <Alert type={"alert"} title={'You can repost this content on your own social feeds but other usage is restricted on this plan'} message={`To use unlimited content for paid advertising, email marketing or other usage types you will need to upgrade to ${proPlan.name}.`}>
                            <UpgradeButton size={"small"} theme={"alert"} brand={brand}/>
                        </Alert>
                    )}

                    {showSocialsWarning && (
                        <Alert
                            type={"warning"}
                            title={"Connect your social accounts"}
                            message={"To use completed content you must have connected your social profiles with #gifted."}
                            ctaLabel={"Update social profiles"}
                            ctaAction={() => navigate('/brand-profile')}
                        />
                    )}

                    {children}

                    {selectedInfluencer ?
                        (
                            <InfluencerPosts
                                influencer={selectedInfluencer.influencer}
                                posts={view?.influencerPosts || []}
                                selectedPost={selectedPost}
                                loading={loadingView}
                                onPostSelected={setSelectedPost}
                                onSubmitReview={() => setModalState((prev) => ({
                                    ...prev,
                                    influencer: view.influencer,
                                    show: true
                                }))}
                                onGoBack={handleGoBack}
                            />
                        ) : (
                            showList && (
                                <CompletedContentList
                                    items={list}
                                    loading={loading}
                                    onClick={handleOnClick}
                                />
                            )
                        )
                    }

                    {list.length === 0 && !loading && renderEmpty()}
                </Body>
            </LeftContent>

            <RightContent>
                {selectedInfluencer?.influencer ? (
                    <>
                        <InfluencerInfo
                            data={selectedInfluencer?.influencer}
                            onViewReviews={handleViewReviews}
                            showAnalytics={!!settings?.features['completed.content.stats']}
                            mediaInsight={{
                                comments: selectedPost?.insights.comments || 0,
                                likes: selectedPost?.insights.likes || 0,
                                engagementRate: selectedPost?.insights.engagement_rate || 0,
                                shares: selectedPost?.insights.shares || 0,
                                views: selectedPost?.insights.views || 0,
                                post_type: selectedPost?.type,
                                saves: selectedPost?.insights.saves || 0,
                            }}
                            onViewProfile={handleViewProfile}
                            onCompletedContent={true}
                        />
                        <InfluencerCard
                            influencer={{
                                ...selectedInfluencer?.influencer,
                                attributes: selectedInfluencer?.attribute as AttributeValue[],
                                tags: selectedInfluencer?.tags as IdName[]
                            }}
                            show={viewProfile}
                            loadingReviews={loadingReviews}
                            onClose={() => setViewProfile(false)}
                        />

                        {selectedPost && (
                            <InfluencerPostInfo
                                post={selectedPost}
                            />
                        )}
                    </>
                ) : (
                    <HelpArea>
                        <NoResult image={'camera'} title={'How is content discovered?'} message={tagsSentence || ''}>
                            <LinkDescription style={{ width: '50%', textAlign: "center", lineHeight: 1.4 }}>
                                <a target={'_blank'} href={'https://hashgifted.com/help/using-completed-content'}>Learn more about tagging and completed content</a>
                            </LinkDescription>
                        </NoResult>
                    </HelpArea>
                )}

                <ReviewModal
                    {...modalState}
                    onClose={() => setModalState((prev) => ({
                        ...prev,
                        influencer: undefined,
                        completed: false,
                        show: false,
                    }))}
                    onSubmit={handleSubmitReview}
                />
            </RightContent>
        </>
    );
}
