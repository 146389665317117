import {useEffect, useState, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from 'api';
import {
    Actions, useAppDispatch,
    useAppSelector
} from 'store';
import { Helpers } from 'utils';
import AnalyticsEvents from 'services/analyticsEvents';
import {
    Breadcrumbs,
    GiftFlowCard,
    GiftModal,
    StatusLoader
} from 'app/components';
import {
    ageRange,
    AudienceForm,
    CreativeForm,
    DeliverablesForm,
    followersRange,
    GiftTypeForm,
    ShopifyForm
} from 'app/forms'
import { PrivateLayout } from 'app/layouts';
import { GiftFlowPageHeader } from './Header';
import {Crop} from "react-image-crop";
import {Breadcrumb} from "../../components/Breadcrumbs";
import {ClientSettings} from "../../../services/settingsService";
import { Alert } from '../../components';
import { InviteCreatorsForm } from 'app/forms/InviteCreatorsForm';
import ReactGA from 'react-ga4';

const Container = styled.div`
    
    margin-top: 24px;
`;

const AlertContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 20px;
    transform: translateY(-60px);
`;

export function GiftFlowPage() {
    const navigate = useNavigate();

    const dispatcher = useAppDispatch();

    const {
        gift: {
            flow: { step },
            item,
            status,
            loading: loadingGift,
        },
        global: {
            alert,
            loading: loadingGiftTypes,
            settings
        },
        profile: {
            brand,
        }
    } = useAppSelector((state) => state);

    const params = useParams();

    const [modalState, setModalState] = useState<Dynamic>({
        show: false,
        status: ''
    });

    const analyticsEvents = useMemo(() => new AnalyticsEvents(settings as ClientSettings), [settings]);

    const [creativeFormMedia, setCreativeFormMedia] = useState(item?.media)

    const [currentStep, setCurrentStep] = useState<GiftFlowStep>('start');

    const [fetchingGift, setFetchingGift] = useState(false);

    useEffect(() => {
        if (params.id) {
            setFetchingGift(true)
            void dispatcher(Actions.Gift.getGift(params.id)).then(() => {
                setNextStep('creative')
                setCurrentStep('creative')    
                setFetchingGift(false)
            })
        }

        return () => {
            dispatcher(Actions.Gift.clearState());
        }
    }, [params.id, dispatcher]);

    // If the user tries to leave the page without saving
    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        }
    }, [])

    const handleUnload = (e: any) => {
        const message = "Changes you made may not be saved!"; // Modern browsers don't actually let you change this, but it's needed for legacy support.

        (e || window.event).returnValue = message;
        return message;
    }

    // If the item changes then we need to refresh the media
    useEffect(() => {
        setCreativeFormMedia(item?.media);
    }, [item]);

    useEffect(() => {
        return () => {
            dispatcher(Actions.App.setAlert(null));
            dispatcher(Actions.Gift.clearState());
        }
    }, [dispatcher]);


    useEffect(() => {
        if (alert) {
            dispatcher(Actions.App.setAlert(null));
        }
    }, [step])

    useEffect(() => {
        analyticsEvents.dashboardCreateGiftOpened();

        ReactGA.event({
            category: "custom event",
            action: "enter_create_gift",
            label: "Brand entered create gift flow", // optional
        });        


        // ReactGA.event({
        //     category: "custom event",
        //     // @ts-ignore
        //     transaction_id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        //     action: "purchase",
        //     label: "Brand subscribed", // optional
        //     value: 100,
        //     // @ts-ignore
        //     currency: 'AUD',
        //     items: [
        //         {
        //             item_id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        //             item_name: 'Gift',
        //             affiliation: 'HashGifted',
        //             price: 100,
        //             quantity: 1,
        //         }
        //     ]
        // });        

        
    }, []);

    useEffect(() => {
        analyticsEvents.giftCreateFlow(step);

        const url = window.location.href;
        const hashIndex = url.lastIndexOf('#');

        if (hashIndex !== -1) {
            const hashFragment = url.slice(hashIndex + 1);
            const lastWord = hashFragment.split('#').pop();
            if (lastWord === 'invite_creators') {
                setNextStep(lastWord as GiftFlowStep)
                setCurrentStep(lastWord as GiftFlowStep);
            }
        }    

    }, [step]);

    /*
    * This is for browser back/forward button functionality
    */
    useEffect(() => {
        const handleLocationChange = () => {
          const url = window.location.href;
          const hashIndex = url.lastIndexOf('#');

          if (hashIndex !== -1) {
            const hashFragment = url.slice(hashIndex + 1);
            const lastWord = hashFragment.split('#').pop();
            if (lastWord !== currentStep) {
                setNextStep(lastWord as GiftFlowStep)
                setCurrentStep(lastWord as GiftFlowStep);
            }
          }
        };

        window.addEventListener('hashchange', handleLocationChange);

        return () => {
          window.removeEventListener('hashchange', handleLocationChange);
        };
      }, [params]);

    useEffect(() => {
        // scroll to top whenever the
        // value of step changes

        document
            .getElementsByTagName('main')[0]
            .scrollTo({
                top: 0,
                behavior: 'smooth'
            });

        if (brand) {
            switch (step) {
                case 'start':
                    // Clear the state when we click on the breadcrumbs to the gift type screen
                    dispatcher(Actions.App.setAlert(null));
                    dispatcher(Actions.Gift.clearState());
                    setCreativeFormMedia([]);
                    analyticsEvents.giftCreateStart(brand);
                    break;
                case 'creative':
                    analyticsEvents.giftCreateCreative(brand);
                    break;
                case 'audience':
                    analyticsEvents.giftCreateAudience(brand);
                    break;
                case 'deliverables':
                    analyticsEvents.giftCreateDeliverables(brand);
                    break;
            }
        }
    }, [brand, step]);

    const getBreadcrumbs = (): Breadcrumb[] => {
        const breadcrumbs = [
            { id: 'dashboard', name: 'Dashboard', route: '/' },
        ];

        switch (step) {
            case 'start':
                return [
                    ...breadcrumbs,
                    { id: 'giftType', name: 'Gift Type' }
                ];

            case 'creative':
                return [
                    ...breadcrumbs,
                    { id: 'giftType', name: 'Gift Type', destination: 'start' },
                    { id: 'creative', name: 'Creative' }
                ];

            case 'audience':
                return [
                    ...breadcrumbs,
                    { id: 'giftType', name: 'Gift Type', destination: 'start' },
                    { id: 'creative', name: 'Creative', destination: 'creative' },
                    { id: 'audience', name: 'Audience' }
                ];

            case 'deliverables':
                return [
                    ...breadcrumbs,
                    { id: 'giftType', name: 'Gift Type', destination: 'start' },
                    { id: 'creative', name: 'Creative', destination: 'creative' },
                    { id: 'audience', name: 'Audience', destination: 'audience' },
                    { id: 'deliverables', name: 'Platforms' }
                ];

            case 'shopify_product':
            case 'shopify_gift_card':
                return [
                    ...breadcrumbs,
                    { id: 'giftType', name: 'Gift Type', destination: 'start' },
                    { id: 'creative', name: 'Creative' }
                ];

            case 'subscription_plans':
                return [
                    ...breadcrumbs,
                    { id: 'giftType', name: 'Gift Type', destination: 'start' },
                    { id: 'creative', name: 'Creative', destination: 'creative' },
                    { id: 'audience', name: 'Audience', destination: 'audience' },
                    { id: 'deliverables', name: 'Deliverables', destination: 'deliverables' },
                    { id: 'subscription', name: 'Subscription' }
                ];

            case 'subscription_checkout':
                return [
                    ...breadcrumbs,
                    { id: 'giftType', name: 'Gift Type', destination: 'start' },
                    { id: 'creative', name: 'Creative', destination: 'creative' },
                    { id: 'audience', name: 'Audience', destination: 'audience' },
                    { id: 'deliverables', name: 'Deliverables', destination: 'deliverables' },
                    { id: 'subscription', name: 'Subscription', destination: 'subscription_plans' },
                    { id: 'checkout', name: 'Checkout' }
                ];

            default: return breadcrumbs;
        }
    }

    const getMessage = () => {
        switch (status) {
            case 'error':
                return 'Request failed';

            case 'delete-gift-creative-media':
                return 'Deleting media';

            case 'set-gift-creative-media':
                return 'Uploading media';

            default: return '';
        }
    }

    const deleteGiftCreativeMedia = (mediaId: string) => {
        if (item?.uid) {
            dispatcher(Actions.Gift.deleteGiftCreativeMedia(item.uid, mediaId, (media) => {
                setCreativeFormMedia(media)
            })).catch((error: any) => {
                throw error
            })
        }
    }

    const setCreateGiftType = (form: GiftTypeForm, draft = false) => {

        let nextStep: GiftFlowStep
        switch (form.gift_type) {
            case 'PRODUCT':
                nextStep = brand?.shopify ? 'shopify_product' : 'creative';
                break;
            case 'GIFT_VOUCHER':
                nextStep = brand?.shopify ? 'shopify_gift_card' : 'creative';
                break;
            default:
                nextStep = 'creative';
                break;
        }

        if (item?.uid) {
            dispatcher(Actions.Gift.setGiftType(
                item.uid,
                form,
                {
                    draft: draft,
                    callback: () => {
                        if (draft) {
                            setGiftDraft();
                        } else {
                            setNextStep(nextStep);
                        }
                    }
                }
            )).catch((error: any) => {throw error});
        } else {
            dispatcher(Actions.Gift.createGiftType(
                form,
                {
                    draft: draft,
                    callback: () => {
                        if (draft) {
                            setGiftDraft();
                        } else {
                            setNextStep(nextStep);
                        }
                    }
                }
            )).catch((error: any) => {throw error});
        }
    }

    const setGiftAudience = (form: AudienceForm, draft = false) => {
        if (item?.uid) {

            if (form.followers) {
                if (form.followers[1] === 100000) {
                    form.followers[1] = 2147483647
                }
            }

            if (form.age[1] === 100) {
                form.age[1] = 2147483647
            }

            if (form.gender) {
                form.gender.forEach((gender: IdName) => {
                    gender.id = parseInt(gender.id as string)
                })
            }

            const data = {
                ...item,
                ...item.attributes,
                ...form,
            }

            dispatcher(Actions.Gift.setGiftAudience(
                item.uid,
                data,
                {
                    draft: draft,
                    callback: () => {
                        if (draft) {
                            setGiftDraft();
                        } else {
                            setNextStep('deliverables');
                        }
                    }
                }
            )).catch((error: any) => {throw error});
        }
    }

    const setGiftAudienceLocations = (data: GiftLocation[]): void => {
        if (!brand) {
          throw new Error('brand missing');
        }
        if (!item) {
          throw new Error('gift missing');
        }

        API.Gift.setGiftAudienceLocations(brand.uid, item.uid, data).catch((error: any) => {throw error})
      };

    const setGiftCreative = (form: CreativeForm, draft = false) => {
        if (item?.uid) {
            dispatcher(Actions.Gift.setGiftCreative(
                item.uid,
                form,
                {
                    draft: draft,
                    callback: () => {
                        if (draft) {
                            setGiftDraft();
                        } else {
                            setNextStep('audience');
                        }
                    }
                }
            )).catch((error: any) => {throw error});
        }
    }

    const setGiftCreativeMedia = (file: Blob, thumbnail: Blob, crop: Crop) => {
        if (!item || !settings) {
            return;
        }
        if (settings?.files.temp_upload_url) {
            dispatcher(Actions.Gift.setGiftCreativeMediaAndThumbnail(item.uid, file, thumbnail, crop, settings, (media: Media[]) => {
                setCreativeFormMedia(media)
            })).catch((error: any) => {throw error});
        } else {
            dispatcher(Actions.Gift.setGiftCreativeMedia(item.uid, file, crop, settings, (media: Media[]) => {
                setCreativeFormMedia(media)
            })).catch((error: any) => {throw error});
        }
    }

    const setGiftCreativeShopify = (form: ShopifySelectionForm) => {
        if (item?.uid) {
            dispatcher(Actions.Gift.setGiftCreativeShopify(item.uid, form.selections))
                .then(() => {
                    dispatcher(Actions.Gift.getGift(item.uid)).catch((error: any) => {throw error});
                }).catch((error: any) => {throw error});
        }
    }

    const setGiftDeliverables = (
        form: DeliverablesForm,
        draft = false
    ) => {

        if (item?.uid && brand && settings) {
            // if (giftAPI.requiresSubscription(item?.gift_type, brand, settings)) {
            //     dispatcher(Actions.Gift.setGiftDeliverables(
            //         item.uid,
            //         form,
            //         {
            //             draft: true,
            //             callback() {
            //                 dispatcher({
            //                     type: Constants.Gift.Flow,
            //                     payload: { step: 'subscription_plans' }
            //                 });
            //             }
            //         }
            //     ));
            // } else {
                dispatcher(Actions.Gift.setGiftDeliverables(
                    item.uid,
                    form,
                    {
                        draft: draft,
                        callback: () => {
                            if (draft) {
                                setGiftDraft();
                            } else {
                                // change status to submitted
                                // note: not automatically handled
                                //   by the server
                                dispatcher(Actions.Gift.setGiftStatus(
                                    item.uid,
                                    'DRAFT',
                                    () => {
                                        setModalState({
                                            show: true,
                                            type: 'reviewing'
                                        });

                                        if (brand) {
                                            const updatedItem = {
                                                ...item,
                                                analytic: {
                                                    ...item.analytic,
                                                    gift_status: "SUBMITTED",
                                                    gift_audience_gender: Object(item.attributes).gender
                                                }
                                            } as GiftItem;
                                            analyticsEvents.giftCreateCompleted(
                                                { ...updatedItem, brand },
                                                brand
                                            );

                                            ReactGA.event({
                                                category: "custom event",
                                                action: "submit_gift",
                                                label: "Brand submitted a gift", // optional
                                            });
                                        }
                                    }
                                )).catch((error: any) => {throw error});
                            }
                        }
                    }
                )).catch((error: any) => {throw error});
            // }
        }
    }

    const setGiftDraft = () => {
        setModalState({
            show: true,
            type: 'drafted'
        });
    }

    const setNextStep = (step: GiftFlowStep) => {

        dispatcher(Actions.Gift.setFlowStep(step))

        if (modalState.show) {
            setModalState({
                show: false,
            })    
        }

        // This is for back button functionality
        if (window.location.href.indexOf(step) === -1) {
            window.location.href += `#${step}`;
        }
    }

    const dragAction = (fromIndex: number, toIndex: number) => {
        if (item && brand) {
            //@ts-ignore
            const data = [...creativeFormMedia];
            const newItem = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, newItem);

            setCreativeFormMedia(data)

            const mediaIdList: string[] = []
            if (creativeFormMedia) {
                creativeFormMedia.forEach((file) => {
                    mediaIdList.push(file.uid)
                })
            }

            dispatcher(Actions.Gift.setGiftCreativeMediaOrder(brand?.uid, item.uid, mediaIdList)).catch((error: any) => {throw error});
        }
    }

    const cropAction = (index: number, crop: Crop | undefined) => {
        if (item && brand) {


            const data = [...item.media];
            //@ts-ignore
            data[index].metadata.crop = crop
            item.media = data

            const mediaIdList: string[] = []
            item.media.forEach((file) => {
                mediaIdList.push(file.uid)
            })

            dispatcher(Actions.Gift.setGiftCreativeMediaCrop(brand?.uid, item.uid, mediaIdList[index], crop)).catch((error: any) => {throw error});

        }
    }

    const renderForm = useCallback(() => {
        switch (step) {
            case 'start':
                const giftTypeData = {
                    gift_type: item?.gift_type || 'PRODUCT',
                };

                return (
                    <GiftTypeForm
                        key={item?.uid}
                        data={giftTypeData}
                        status={status}
                        loadingGift={loadingGift || fetchingGift}
                        loadingGiftTypes={loadingGiftTypes}
                        primaryAction={{
                            type: 'submit',
                            name: 'Next',
                            action: setCreateGiftType
                        }}
                    >
                        <GiftFlowPageHeader title={'Choose your gift campaign type'} />
                    </GiftTypeForm>
                );

            case 'creative':
                const creativeData = {
                    name: item?.name || '',
                    description: item?.description || '',
                    value: item?.value || null
                };

                return (
                    <CreativeForm
                        dragAction={dragAction}
                        cropAction={cropAction}
                        type={item?.gift_type}
                        data={creativeData}
                        media={creativeFormMedia?.length ? creativeFormMedia : item?.media}
                        status={status}
                        disabledActions={Helpers.isEmpty(creativeData) ? true : undefined}
                        loading={loadingGift || fetchingGift}
                        primaryAction={{
                            type: 'submit',
                            name: 'Next',
                            action: setGiftCreative
                        }}
                        secondaryAction={{
                            type: 'submit',
                            name: 'Save as draft',
                            action: (form) =>
                                setGiftCreative(form as CreativeForm, true)
                        }}
                        onUploadMedia={setGiftCreativeMedia}
                        onDeleteMedia={deleteGiftCreativeMedia}
                    >
                        <GiftFlowPageHeader title={'Make your gift stand out'} />
                    </CreativeForm>
                );
            case 'audience':
                let brandTags = brand?.tags || [];

                if (brandTags.length > 3) {
                    brandTags = brandTags.slice(0, 3)
                }

                const audienceData = {
                    tags: item?.tags || brandTags,
                    locations: item?.locations,
                    followers: item?.max_followers ? [
                        item.min_followers, item.max_followers
                    ] : null,
                    age: [
                        item?.min_age || ageRange[0],
                        item?.max_age || ageRange[1]
                    ]
                } as AudienceForm;

                const attrs: any = {}
                if (item && item.attributes) {
                    for (const attr in item.attributes) {
                        if (attr) {
                            if (item.attributes[attr]?.name) {
                                if (!attrs[item.attributes[attr]?.name]) {
                                    attrs[item.attributes[attr].name] = [
                                        {
                                            label: item.attributes[attr].producer_label,
                                            name: item.attributes[attr].producer_label,
                                            id: item.attributes[attr].id
                                        }
                                    ]
                                } else {
                                    attrs[item.attributes[attr].name].push({
                                        label: item.attributes[attr].producer_label,
                                        name: item.attributes[attr].producer_label,
                                        id: item.attributes[attr].id
                                    })
                                }

                            }
                        }
                    }
                }

                return (
                    <AudienceForm
                        data={{
                            ...attrs,
                            ...audienceData,
                        }}
                        status={status}
                        loading={loadingGift}
                        disabledActions={Helpers.isEmpty(audienceData) ?
                            true : undefined}
                        primaryAction={{
                            type: 'submit',
                            name: 'Next',
                            action: setGiftAudience
                        }}
                        setGiftAudienceLocations={setGiftAudienceLocations}
                        secondaryAction={{
                            type: 'submit',
                            name: 'Save as draft',
                            action: (form) =>
                                setGiftAudience(form as AudienceForm, true)
                        }}
                    >
                        <GiftFlowPageHeader title={'Who are you looking for?'} />
                    </AudienceForm>
                );

            case 'deliverables':
                const deliverablesData = {
                    deliverables: item?.deliverables || []
                };

                return (
                    <DeliverablesForm
                        data={deliverablesData}
                        status={status}
                        disabledActions={false}
                        loading={loadingGift}
                        primaryAction={{
                            type: 'submit',
                            name: 'Create Gift',
                            action: setGiftDeliverables
                        }}
                        secondaryAction={{
                            type: 'submit',
                            name: 'Save as draft',
                            action: (form) =>
                                setGiftDeliverables(form as DeliverablesForm, true)
                        }}
                    >
                        <GiftFlowPageHeader title={`What content are you open to receiving?`} />
                    </DeliverablesForm>
                );

            case 'invite_creators':

                const inviteData = {
                    brand: brand,
                    giftSlug: item?.slug || null
                };

                return (
                    <InviteCreatorsForm
                        data={inviteData}
                        status={status}
                        disabledActions={false}
                        loading={loadingGift}
                        primaryAction={{
                            type: 'submit',
                            name: 'Invite creators',
                            action: () => {}
                        }}
                        secondaryAction={{
                            type: 'submit',
                            name: 'Done/No thanks',
                            action: () =>
                                navigate('/')
                        }}
                    
                    >
                        <GiftFlowPageHeader title={`Invite any creators you already work with`} />

                    </InviteCreatorsForm>
                )

            case 'shopify_product':
                return (
                    <ShopifyForm
                        mode='PRODUCT'
                        data={undefined}
                        status={status}
                        loading={loadingGift}
                        primaryAction={{
                            type: 'submit',
                            name: 'Next',
                            action: setGiftCreativeShopify
                        }}
                        secondaryAction={{
                            type: 'submit',
                            name: 'Create manually',
                            action: () => setNextStep('creative')
                        }}
                    >
                        <GiftFlowPageHeader title={'Pick products from Shopify'} />
                    </ShopifyForm>
                );

            case 'shopify_gift_card':
                return (
                    <ShopifyForm
                        mode='GIFT_CARD'
                        data={undefined}
                        status={status}
                        loading={loadingGift}
                        primaryAction={{
                            type: 'submit',
                            name: 'Next',
                            action: setGiftCreativeShopify
                        }}
                        secondaryAction={{
                            type: 'submit',
                            name: 'Create Manually',
                            action: () => setNextStep('creative')
                        }}
                    >
                        <GiftFlowPageHeader title={'Pick a gift card from Shopify'} />
                    </ShopifyForm>
                );
            default: return (<h1 style={{ textAlign: 'center' }}>{step}</h1>);
        }
    },[step, item, creativeFormMedia, alert?.errors, status, loadingGift, loadingGiftTypes, setCreateGiftType, dragAction, setGiftCreative, setGiftCreativeMedia, deleteGiftCreativeMedia, brand?.tags, setGiftAudience, setGiftDeliverables, setGiftCreativeShopify, setNextStep])

    return (
        <PrivateLayout>
            <Container data-testid={'giftflow-page'}>
                <Breadcrumbs
                    items={getBreadcrumbs()}
                    onClick={setNextStep}
                />

                <GiftFlowCard>
                        {alert && (
                            alert?.errors && (
                                <AlertContainer>
                                    {Object.keys(alert.errors).map((key) => {
                                        const errors = alert.errors;
                                        if (!errors) {
                                            return null;
                                        }
                                        const error = errors[key];
                                        return (
                                            <Alert key={key} type="error" title={error[0]}/>
                                        );
                                    })}
                                </AlertContainer>
                            )
                        )}
                    {renderForm()}
                </GiftFlowCard>
            </Container>

            <GiftModal
                {...modalState}
                data={item}
                onClose={() => navigate('/')}
                primaryAction={{
                    name: modalState.type === 'reviewing' || modalState.type === 'subscribed-reviewing' ?
                        'Go to final step' : 'Go to draft gifts',
                    action: () => modalState.type === 'reviewing' || modalState.type === 'subscribed-reviewing' ? setNextStep('invite_creators') : navigate('/', {
                        state: {
                            selectedTabId: modalState.type === 'drafted' ? 'draft' : (modalState.type === 'reviewing' || modalState.type === 'subscribed-reviewing') && 'submitted'
                        }
                    })
                }}
            />

            <StatusLoader
                show={status === 'error'}
                status={status}
                title={'Create Gift'}
                message={getMessage()}
            />
        </PrivateLayout>
    );
}
