import veroService from './veroService';
import googleTagManagerService from './googleTagManagerService';
import logRocketService from 'services/logRocketService';
import {ClientSettings} from "./settingsService";

export interface AnalyticsProfile {
    id: string;
    email: string;
    name?: string;
    headshot_image?: string;
    last_seen_at?: string;
    status: string;
    brand_id?: string;
    brand_name?: string;
    brand_description?: string;
    brand_logo_url?: string;
    brand_website?: string;
}

class AnalyticsService {

    private settings: ClientSettings;

    private profile: AnalyticsProfile | null = null;

    constructor(settings: ClientSettings) {
        this.settings = settings;
    }

    identify(profile: AnalyticsProfile) {
        this.profile = profile;
        veroService.identify(this.profile);
        logRocketService.identify(this.profile);
        // console.log('identify', this.profile);
    }

    unidentify() {
        this.profile = null;
        veroService.unidentify();
        logRocketService.unidentify();
        // console.log('unidentify');
    }

    track(eventName: string, props?: Dynamic) {
        if (!props) {
            props = {};
        }
        googleTagManagerService.track(eventName, props);
        veroService.track(eventName, props);

        logRocketService.track(eventName, props);
        if (this.profile) {
            logRocketService.identify(this.profile);
        }
        // console.log('track', eventName, props);
    }


    public createProfileProps(profile: RepresentativeProfile): AnalyticsProfile {
        return {
            id: profile.uid,
            name: profile.name,
            email: profile.auth.email,
            headshot_image: profile.headshot_image || undefined,
            last_seen_at: profile.last_seen_at || undefined,
            status: profile.status,
            brand_id: profile.brand?.uid,
            brand_name: profile.brand?.name,
            brand_description: profile.brand?.description,
            brand_logo_url: profile.brand?.logo,
            brand_website: profile.brand?.website,
        };
    }
}

export default AnalyticsService;
