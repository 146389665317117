import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import ReactGA from 'react-ga4';
import {Store} from './store';
import App from './app';
import './index.css';
import {createRoot} from "react-dom/client";

const container = document.getElementById('root');
const root = createRoot(container as Element);

ReactGA.initialize('G-WDQ13RK1YE', {});

///
/// https://stackoverflow.com/questions/49055172/react-component-mounting-twice
///
root.render(
    <>
        {/*<React.StrictMode>*/}
            <Provider store={Store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        {/*</React.StrictMode>*/}
    </>
);

