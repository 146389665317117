import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from 'api';
import {
    Actions,
    useAppDispatch,
    useAppSelector
} from 'store';
import {
    Alert,
    Breadcrumbs,
    GiftViewCard,
    GiftModal,
    StatusLoader
} from 'app/components';
import {
    ageRange,
    AudienceForm,
    CreativeForm,
    DeliverablesForm,
    followersRange
} from 'app/forms';
import { PrivateLayout } from 'app/layouts';
import { GiftViewPageHeader } from './Header';
import { CompletedContent } from './CompletedContent';
import { WavesTabs } from './WavesTabs';
import { GiftReviews } from './GiftReviews';
import {Crop} from "react-image-crop";
import {Breadcrumb} from "../../components/Breadcrumbs";
import { ErrorModal } from 'app/components/ErrorModal';
import { GiftFlowPageHeader } from '../GiftFlowPage/Header';
import errorReporter from "../../../services/errorReporter";
import AnalyticsEvents from "services/analyticsEvents";
import {ClientSettings} from "../../../services/settingsService";

export type GiftTabType = 'waves' | 'audience' | 'creative' | 'platforms' | 'completed-content' | 'reviews';

export function GiftViewPage() {
    const params = useParams();

    const navigate = useNavigate();

    const dispatcher = useAppDispatch();

    const {
        auth: {user},
        global: {alert, settings },
        gift: {
            item,
            loading,
            status: giftStatus
        },
        profile: { brand },
        wave: {
            status: waveStatus
        }
    } = useAppSelector((state) => state);

    const [giftLoaded, setGiftLoaded] = useState<boolean>();

    useEffect(() => {
        if (!giftLoaded && item) {
            setGiftLoaded(true);
            switch (item?.status) {
                case 'ACTIVE':
                case 'CLOSED':
                case 'COMPLETED':
                case 'SCHEDULED':
                    setSelectedTab('waves');
                    break;
                default:
                    setSelectedTab('creative');
            }
        }
    }, [item]);

    const [selectedTab, setSelectedTab] = useState<GiftTabType>('waves');

    const [modalGiftState, setModalGiftState] = useState<Dynamic>({
        show: false,
    });

    // This actually gives us the smoothest drag and drop experience for reordering media
    const [forceUpdate, setForceUpdate] = useState<number>(0);

    const [waveByUrl, setWaveByUrl] = useState<WaveItem>()
    const [linkToStatus, setLinkToStatus] = useState<string>('')

    const [parentCreativeFormData, setParentCreativeFormData] = useState(undefined)
    const [parentAudienceFormData, setParentAudienceFormData] = useState(undefined)
    const [parentAudienceLocationFormData, setParentAudienceLocationFormData] = useState(undefined)
    const [parentDeliverablesFormData, setParentDeliverablesFormData] = useState(undefined);

    const analyticsEvents = useMemo(() => new AnalyticsEvents(settings as ClientSettings), [settings]);


    const status = useMemo(() => {
        switch (item?.status) {
            case 'DRAFT':
                return 'draft';
            case 'ACTIVE':
            case 'CLOSED':
                return 'active';
            case 'SUBMITTED':
                return 'submitted';
            case 'REJECTED':
                return 'declined';
            case 'CANCELED':
                return 'past';
            case 'COMPLETED':
                return 'past';
            case 'SCHEDULED':
                return 'scheduled';
            default:
                return ''
        }
    }, [item])

    const featuredImage = useMemo(() => item?.media?.[0]?.thumbnail_path, [item]);

    useEffect(() => {
        document
            .getElementsByTagName('main')[0]
            .scrollTo({
                top: 0,
                behavior: 'smooth'
            });

        const analyticsEvents = new AnalyticsEvents(settings as ClientSettings);
        if (item && brand) {
            switch (selectedTab) {
                case 'waves':
                    analyticsEvents.giftWavesOpened(item, brand);
                    break;
                case 'audience':
                    analyticsEvents.giftAudienceOpened(item, brand);
                    break;
                case 'creative':
                    analyticsEvents.giftCreativeOpened(item, brand);
                    break;
                case 'platforms':
                    analyticsEvents.giftDeliverablesOpened(item, brand);
                    break;
                case 'completed-content':
                    analyticsEvents.giftContentOpened(item, brand);
                    break;
            }
        }
    }, [item, brand, selectedTab]);

    useEffect(() => {
        if (item) analyticsEvents.giftManageOpened(item)
    }, [item])

    useEffect(() => {
        if (params.id) {
            void dispatcher(Actions.Gift.getGift(params.id));
        }

        if (params.id && params.waveId) {

            if (['submitted', 'shortlisted', 'accepted', 'rejected'].includes(params.waveId)) {
                setLinkToStatus(params.waveId);
            }

            void dispatcher(Actions.Wave.getWaveItem(params.id, params.waveId, true, true, waveSetCallback));
        }

        return () => {
            dispatcher(Actions.Gift.clearState());
        }
    }, [params.id, dispatcher, user, params.waveId, params.influencerId]);

    const waveSetCallback = (newWave: WaveItem) => {
        setWaveByUrl(newWave)
    }

    const getBreadcrumbs = (): Breadcrumb[] => {
        return ([
            { id: 'dashboard', name: 'Dashboard', route: `/?tab=${status}` },
            { id: 'gift-type', name: status.length ? `${status} Gifts` : '...' },
        ]);
    }

    const getGiftStatusMessage = () => {
        switch (giftStatus) {
            case 'success':
                return 'Request successful';

            case 'error':
                return 'Request failed';

            case 'delete-gift-creative-media':
                return 'Deleting media';

            case 'duplicate-gift':
                return 'Duplicating gift';

            case 'set-gift-audience':
                return 'Updating gift attributes';

            case 'set-gift-creative':
                return 'Updating gift creative info';

            case 'set-gift-creative-media':
                return 'Uploading media';

            case 'set-gift-deliverables':
                return 'Updating gift deliverables'

            case 'set-gift-status-active':
            case 'set-gift-status-closed':
                return 'Completing gift';

            case 'set-gift-status-delete':
                return 'Cancelling gift';

            case 'set-gift-status-draft':
                return 'Submitting gift';

            case 'set-gift-status-hide':
                return 'Hiding gift';

            case 'set-gift-status-save':
                return 'Saving gift';

            case 'set-gift-status-submitted':
                return 'Moving gift to edit mode';

            case 'set-gift-status-unhide':
                return 'Un-hiding this gift';

            case 'delete-gift':
                return 'Deleting gift';
            default: return '';
        }
    }

    const deleteGiftCreativeMedia = (mediaId: string) => {
        if (params.id) {
            dispatcher(Actions.Gift.deleteGiftCreativeMedia(params.id, mediaId)).catch((error: any) => {throw error});
        }
    }

    const handleDuplicate = () => {
        if (params.id) {
            dispatcher(Actions.Gift.duplicateGift(params.id, () => {
                navigate('/create');
            })).catch((error: any) => {throw error});
        }
    }

    const setGiftAudience = (form: AudienceForm) => {

        if (form.followers) {
            if (form.followers[1] === 100000) {
                form.followers[1] = 2147483647
            }
        }

        if (form.age) {
            if (form.age[1] === 100) {
                form.age[1] = 2147483647
            }
        }

        if (form.gender) {
            form.gender.forEach((gender: IdName) => {
                gender.id = parseInt(gender.id as string)
            })
        }

        if (item?.uid) {
            dispatcher(Actions.Gift.setGiftAudience(item.uid, form)).catch((error: any) => {throw error});
        }
    }

    const setGiftAudienceLocations = (data: GiftLocation[]) => {
        if (item == null || item.brand == null) {
            throw new Error('gift or brand was null');
        }
        API.Gift.setGiftAudienceLocations(item?.brand?.uid, item?.uid, data)
            .catch((reason) => {
                errorReporter.report('failed to set audience location', reason);
            });
    }

    const setGiftCreative = (form: CreativeForm) => {
        if (item?.uid) {
            dispatcher(Actions.Gift.setGiftCreative(item.uid, form)).catch((error: any) => {throw error});
        }
    }

    const setGiftCreativeMedia = (file: Blob, thumbnail: Blob, crop: Crop) => {
        if (!item || !settings) {
            return;
        }
        if (settings?.files.temp_upload_url) {
            dispatcher(Actions.Gift.setGiftCreativeMediaAndThumbnail(item.uid, file, thumbnail, crop, settings)).catch((error: any) => {throw error});
        } else {
            dispatcher(Actions.Gift.setGiftCreativeMedia(item.uid, file, crop, settings)).catch((error: any) => {throw error});
        }
    }

    const setGiftDeliverables = (form: DeliverablesForm) => {
        if (item?.uid) {
            dispatcher(Actions.Gift.setGiftDeliverables(item.uid, form)).catch((error: any) => {throw error});
        }
    }

    const setNaigatevOrFlowStep = (locationOrTab: string|GiftTabType) => {
        if (locationOrTab === '/') {
            navigate('/', {
                state: {
                    selectedTabId: status
                }
            });
        } else {
            setSelectedTab(locationOrTab as GiftTabType);
        }
    }

    const handleNavigate = () => {
        navigate(`/gift-view/${params.id}`, { replace: true });
    }

    const backToDashboard = () => {
        navigate('/', { replace: true });
    }

    const handleToggleHide = (hide: boolean) => {

        setModalGiftState({
            type: 'hide-gift',
            show: true,
            primaryAction: {
                name: hide ? 'Stop new applicants' : 'Resume new applicants',
                action: () => {
                    //@ts-ignore
                    if (params.id) {
                        const status = hide ? 'HIDE' : 'UNHIDE';
                        dispatcher(Actions.Gift.setGiftStatus(params.id, status, () => {
                            if (item && brand) {
                                analyticsEvents.giftVisibilityToggled(item, brand, hide);
                            }
                        })).catch((error: any) => {throw error});
                        setModalGiftState((prev) => ({
                            ...prev,
                            show: false
                        }))
                    }
                }
            },
            secondaryAction: {
                name: 'Cancel',
                action: () => setModalGiftState((prev) => ({
                    ...prev,
                    show: false
                }))
            }
        });


    }

    const dragAction = async (fromIndex: number, toIndex: number) => {
        if (item && brand) {
            const data = [...item.media];
            const newItem = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, newItem);
            item.media = data;

            const mediaIdList : Array<string> = []
            item.media.forEach((file) => {
                mediaIdList.push(file.uid)
            })
            // This actually gives us the smoothest drag and drop experience for reordering media
            setForceUpdate(forceUpdate + 1)
            await dispatcher(Actions.Gift.setGiftCreativeMediaOrder(brand?.uid, item.uid, mediaIdList)).catch((error: any) => {throw error});
        }
    }

    const cropAction = (index: number, crop: Crop | undefined) => {
        if (item && brand) {


            const data = [...item.media];
            //@ts-ignore
            data[index].metadata.crop = crop
            item.media = data

            const mediaIdList: string[] = []
            item.media.forEach((file) => {
                mediaIdList.push(file.uid)
            })

            dispatcher(Actions.Gift.setGiftCreativeMediaCrop(brand?.uid, item.uid, mediaIdList[index], crop)).catch((error: any) => {throw error});

        }
    }

    const handleSaveButtonClick = () => {

        dispatcher(Actions.Gift._setLoading(true))

        //@ts-ignore
        if (parentCreativeFormData) {
            setGiftCreative(parentCreativeFormData)
        }

        if (parentAudienceLocationFormData) {
            setGiftAudienceLocations(parentAudienceLocationFormData)
        }

        if (parentAudienceFormData) {
            setGiftAudience(parentAudienceFormData)
        }

        if (parentDeliverablesFormData) {
            setGiftDeliverables(parentDeliverablesFormData)
        }

        if (item) {
            setTimeout(() => {
                dispatcher(Actions.Gift.getGift(item.uid)).catch((error: any) => {throw error});
                dispatcher(Actions.Gift._setLoading(false))
            },1000)
        } else {
            dispatcher(Actions.Gift._setLoading(false))
        }
    }

    const performPrimaryAction = (status: string) => {
        if (!params.id || !item?.gift_type || !brand || !settings) {
            return
        }

        if (status === 'ACTIVE' || status === 'CLOSED') {
            setModalGiftState({
                type: 'completed',
                show: true,
                primaryAction: {
                    name: 'Yes',
                    action: () => {
                        //@ts-ignore
                        dispatcher(Actions.Gift.setGiftStatus(params.id, status, () => {
                            navigate('/', {
                                state: {
                                    selectedTabId: 'past'
                                }
                            })
                        })).catch((error: any) => {throw error});
                    }
                },
                secondaryAction: {
                    name: 'No',
                    action: () => setModalGiftState((prev) => ({
                        ...prev,
                        show: false
                    }))
                }
            });

        } else {

            dispatcher(Actions.Gift.setGiftStatus(params.id, status, (status) => {

                if (['SUBMITTED', 'COMPLETED'].includes(status)) {
                    handleNavigate();

                    const reviewing = status === 'SUBMITTED';
                    const modalName = reviewing ?
                        'Back to dashboard' :
                        'Go to completed gifts';

                        setModalGiftState({
                            type: reviewing ? 'reviewing' : 'completed',
                            show: true,
                            primaryAction: {
                                name: modalName,
                                action: () => {
                                    navigate('/', {
                                        state: {
                                            selectedTabId: !reviewing && 'past'
                                        }
                                    })
                                }
                            },
                    });
                } else {
                    if (status === 'ACTIVE') {
                        setModalGiftState({
                            type: 'completed',
                            show: true,
                            primaryAction: {
                                name: 'Yes',
                                action: () => {
                                        navigate('/', {
                                            state: {
                                                selectedTabId: 'past'
                                            }
                                        })
                                },
                            },
                            secondaryAction: {
                                name: 'No',
                                action: () => setModalGiftState((prev) => ({
                                    ...prev,
                                    show: false
                                }))
                            }
                        });
                    } else  {
                        if (status !== 'DRAFT') {
                            navigate('/', {
                                state: {
                                    selectedTabId: 'submitted'
                                }
                            })
                        }
                    }
                }
            })).catch((error: any) => {throw error});
        }
    }

    const handleHeaderPrimaryActionClick = (status: string) => {
        if (params.id && item?.gift_type && brand && settings) {

            if (status !== 'REJECTED') {
                //@ts-ignore
                if (parentCreativeFormData) {
                    setGiftCreative(parentCreativeFormData)
                }

                if (parentAudienceLocationFormData) {
                    setGiftAudienceLocations(parentAudienceLocationFormData)
                }

                if (parentAudienceFormData) {
                    setGiftAudience(parentAudienceFormData)
                }

                if (parentDeliverablesFormData) {
                    setGiftDeliverables(parentDeliverablesFormData)
                }

                dispatcher(Actions.Gift.getGift(item.uid)).then(() => {
                    setTimeout(() => {
                        performPrimaryAction(status)
                    },0)
                }).catch((error: any) => {throw error});

            } else {
                performPrimaryAction(status)
            }
        }
    }

    const handleHeaderSecondaryActionClick = () => {
        setModalGiftState({
            type: 'delete',
            show: true,
            primaryAction: {
                name: 'Yes',
                action: () => {
                    setModalGiftState({ show: false });

                    dispatcher(Actions.Gift.deleteGift(
                        params.id || '',
                        backToDashboard
                    )).catch((error: any) => {throw error});
                }
            },
            secondaryAction: {
                name: 'No',
                action: () => setModalGiftState((prev) => ({
                    ...prev,
                    show: false
                }))
            }
        });
    }

    const renderAlert = () => {
        if (selectedTab !== 'creative') {
            return null;
        }
        switch (item?.status) {
            case "SUBMITTED":
                return (
                    <Alert
                        type={'info'}
                        title={'Our team is reviewing your gift!'}
                        message={'If you think you missed something. You can still edit your gift & re-submit it.'}
                        ctaLabel={'How do I get my gift approved?'}
                        actionUrl={'https://www.hashgifted.com/help/how-to-get-my-gift-approved'}
                        style={{ marginTop: 24 }}
                    />
                );
            case "REJECTED":
                return (
                    <Alert
                        type={'error'}
                        title={'Your gift has been declined by our team.'}
                        message={item?.admin_notes}
                        ctaLabel={'How do I get my gift approved?'}
                        actionUrl={'https://www.hashgifted.com/help/how-to-get-my-gift-approved'}
                        style={{ marginTop: 24 }}
                    />
                );
            default:
                return null;
        }
    }

    const renderSelectedTab = () => {
        switch (selectedTab) {
            case 'waves':
                return (
                    <WavesTabs
                        giftId={params.id || ''}
                        paramsStatus={status || ''}
                        waveByUrl={waveByUrl}
                        linkToStatus={linkToStatus}
                    >
                        {renderAlert()}
                    </WavesTabs>
                );

            case 'audience':
                const attrs: any = {}
                if (item && item.attributes) {
                    for (const attr in item.attributes) {
                        if (attr) {
                            if (item.attributes[attr]?.name) {
                                if (!attrs[item.attributes[attr]?.name]) {
                                    attrs[item.attributes[attr].name] = [
                                        {
                                            label: item.attributes[attr].producer_label,
                                            name: item.attributes[attr].producer_label,
                                            id: item.attributes[attr].id
                                        }
                                    ]
                                } else {
                                    attrs[item.attributes[attr].name].push({
                                        label: item.attributes[attr].producer_label,
                                        name: item.attributes[attr].producer_label,
                                        id: item.attributes[attr].id
                                    })
                                }
                            }
                        }
                    }
                }

                return (
                    <AudienceForm
                        data={{
                            ...attrs,
                            locations: item?.locations ? item?.locations : [],
                            tags: item?.tags || [],
                            followers: [
                                item?.min_followers || followersRange[0],
                                item?.max_followers || followersRange[1]
                            ],
                            age: [
                                item?.min_age || ageRange[0],
                                item?.max_age || ageRange[1]
                            ]
                        }}
                        status={giftStatus}
                        disabledActions={false}
                        disabledInputs={status === 'declined'}
                        loading={loading}
                        setGiftAudienceLocations={setGiftAudienceLocations}
                        propagateFormToGiftView={setParentAudienceFormData}
                        propagateAudienceLocations={setParentAudienceLocationFormData}
                        noBottomButtons={true}
                        primaryAction={{
                            type: 'submit',
                            name: 'Save changes',
                            action: setGiftAudience
                        }}
                        secondaryAction={{
                            type: 'reset',
                            name: 'Cancel changes',
                            action: () => { }
                        }}
                    >
                        {renderAlert()}
                    </AudienceForm>
                );

            case 'creative':
                const creativeData = {
                    name: item?.name || '',
                    description: item?.description || '',
                    value: item?.value || 0
                };

                return (
                    <CreativeForm
                        dragAction={dragAction}
                        cropAction={cropAction}
                        forceUpdate={forceUpdate}
                        setForceUpdate={setForceUpdate}
                        data={creativeData}
                        propagateFormToGiftView={setParentCreativeFormData}
                        media={item?.media}
                        status={giftStatus}
                        disabledActions={false}
                        disabledInputs={status === 'declined'}
                        loading={loading}
                        noBottomButtons={true}
                        primaryAction={{
                            type: 'submit',
                            name: 'Save changes',
                            action: setGiftCreative
                        }}
                        secondaryAction={{
                            type: 'reset',
                            name: 'Cancel changes',
                            action: () => { }
                        }}
                        onUploadMedia={setGiftCreativeMedia}
                        onDeleteMedia={deleteGiftCreativeMedia}
                    >
                        {renderAlert()}
                    </CreativeForm>
                );

            case 'platforms':
                return (
                    <DeliverablesForm
                        data={{
                            deliverables: item?.deliverables || []
                        }}
                        status={giftStatus}
                        disabledActions={false}
                        disabledInputs={status === 'declined'}
                        loading={loading}
                        noBottomButtons={true}
                        propagateFormToGiftView={setParentDeliverablesFormData}
                        primaryAction={{
                            type: 'submit',
                            name: 'Save changes',
                            action: setGiftDeliverables
                        }}
                        secondaryAction={{
                            type: 'reset',
                            name: 'Cancel changes',
                            action: () => { }
                        }}
                    >
                        <GiftFlowPageHeader title={`What content are you open to receiving?`} />

                        {renderAlert()}
                    </DeliverablesForm>
                );

            case 'completed-content':
                return (
                    <CompletedContent
                        giftId={params.id || ''}
                        paramsStatus={status || ''}
                    >
                        {renderAlert()}
                    </CompletedContent>
                );

            case 'reviews':
                return (
                    <GiftReviews
                        paramsStatus={status || ''}
                    />
                );

            default: return null;
        }
    }

    return (
        <PrivateLayout data-testid={'giftview-page'}>
            <Breadcrumbs
                items={getBreadcrumbs()}
                onClick={setNaigatevOrFlowStep}
            />

            {alert && (

                <ErrorModal
                    show={!!alert}
                    errors={alert.errors}
                    primaryAction={() => dispatcher(Actions.App.setAlert(null))}
                    onClose={() => dispatcher(Actions.App.setAlert(null))}
                />

            )}

            <GiftViewCard>

                <GiftViewPageHeader
                    name={item?.name || ''}
                    image={featuredImage}
                    status={item?.status}
                    paramStatus={status}
                    selectedTab={selectedTab}
                    disableActions={giftStatus !== 'idle'}
                    loading={(loading && giftStatus !== 'set-gift-creative-media' && giftStatus !== 'idle')}
                    onChangeTab={setSelectedTab}
                    onDuplicate={handleDuplicate}
                    onHideToggle={handleToggleHide}
                    onPrimaryActionClick={handleHeaderPrimaryActionClick}
                    onSecondaryActionClick={handleHeaderSecondaryActionClick}
                    completedContentEnabled={item?.is_content_enabled || false}
                    handleSaveButtonClick={handleSaveButtonClick}
                    uid={item?.uid}
                    slug={item?.slug}
                />


                {renderSelectedTab()}
            </GiftViewCard>

            <GiftModal
                {...modalGiftState}
                data={item}
                onClose={() => setModalGiftState((prev) => ({
                    ...prev,
                    show: false
                }))}
            />

            <StatusLoader
                show={giftStatus !== 'idle' && giftStatus !== 'set-gift-creative-media'}
                status={giftStatus}
                title={'My Gift'}
                message={getGiftStatusMessage()}
            />

            <StatusLoader
                show={waveStatus !== 'idle'}
                status={waveStatus}
            />
        </PrivateLayout>
    );
}
