import {FormEvent, PropsWithChildren, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

import {Actions, useAppDispatch, useAppSelector} from 'store';
import {Helpers, Mapping} from 'utils';
import {Button, FormInput, FormLabel, FormMedia, FormMultiSelect, FormTextarea, OptionProps} from 'app/components';
import {Loader} from 'app/components/Loader';
import { FormCheck } from 'app/components/FormCheck';

interface Props {
    brandId?: string;
    data?: BrandForm;
    logo?: string;
    errors?: ApiErrors;
    buttonLabel?: string;
    includeImage?: boolean;
    includeSocial?: boolean;
    disabledAction?: boolean;
    loading?: boolean;
    onSubmit(data: BrandForm): void;
    onChangeLogo(file: File): void;
}

export function BrandProfileForm({
    brandId,
    data,
    logo,
    errors,
    buttonLabel,
    includeImage,
    includeSocial,
    disabledAction,
    loading,
    children,
    onSubmit,
    onChangeLogo
}: PropsWithChildren<Props>) {
    const dispatcher = useAppDispatch();

    const [form, setForm] = useState<BrandForm>(data || {
        name: '',
        description: '',
        website: '',
        tags: [],
        social_profiles: [],
        brand_type: '',
        affiliate_code: ''
    });
    const [brandLogo, setBrandLogo] = useState<string>(logo || '');

    const [maxTags, setMaxTags] = useState<number>(0);

    const [pfpUploaded, setPfpUploaded] = useState('')

    const [companyType, setCompanyType] = useState<string>(data?.brand_type || '');

    const {
        global: {
            tags,
            settings
        }
    } = useAppSelector((state) => state);

    useEffect(() => {
        setMaxTags(settings?.brands.max_tags || 0);
    }, [settings])

    const [preventSubmition, setPreventSubmition] = useState(false)

    useEffect(() => {
        const code = localStorage.getItem('affiliateCode');

        if (code) {
            setForm((prev) => ({
                ...prev,
                affiliate_code: code
            }));
    
        } else {
            setForm((prev) => ({
                ...prev,
                affiliate_code: ""
            }));
    
        }
    }, [])

    const filteredTags = useMemo(() => {
        if (!tags.length) {
            if (!settings) {
                return[];
            }

            return settings.tags.filter((tag) => tag.is_brand_tag);
        }
        return tags.filter((tag) => tag.is_brand_tag)
    }, [tags, settings]);

    useEffect(() => {
        Promise.all([
            dispatcher(Actions.App.getPublicSharedInfo()),
            brandId && dispatcher(Actions.App.getPrivateSharedInfo(brandId))
        ]).catch((error: any) => {
            throw error
        });
    },[dispatcher])

    useMemo(() => {
        const filteredData = {
            ...Helpers.removeObjectKeys(
                { ...data },
                ['tags', 'social_profiles']
            )
        };
        const filteredForm = {
            ...Helpers.removeObjectKeys(
                form,
                ['tags', 'social_profiles']
            ),
            ...(includeImage && ({
                logo: brandLogo
            }))
        };

        if (Helpers.isEmptyObject(filteredForm, true)) {
            return true;
        }

        if (form.tags.length === 0) {
            return true;
        }

        const tagsDiff = Helpers.diffSymmArray(
            data?.tags || [],
            form.tags
        );
        const socialDiff = Helpers.isEqual(
            data?.social_profiles || [],
            form.social_profiles || []
        );
        const disabled = !Helpers.isNullOrUndefined(disabledAction) &&
            Helpers.isEqual(filteredData, filteredForm) &&
            tagsDiff.length === 0 && socialDiff;

        return disabled || loading;
    }, [data, brandLogo, includeImage, disabledAction, loading, form]);

    useEffect(() => {
        if (logo) {
            setBrandLogo(logo);
        }
    }, [logo, data]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!preventSubmition) {
            onSubmit(form);
        }
    }

    const handleChange = (
        name: string,
        value: string | boolean | OptionProps[] | File
    ) => {

        setForm((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    const handleChangeLogo = (name: string, file: File) => {
        if (includeImage) {
            setBrandLogo(URL.createObjectURL(file));
            handleChange(name, file);
            setPfpUploaded('')
        } else {
            onChangeLogo(file);
            setPfpUploaded('')
        }
    }

    const handleChangeSocial = (
        name: string,
        value: string | boolean
    ) => {
        setForm((prev) => {

            const platform = name as SocialPlatformType;
            const profile_url = value as string;

            const newSocialProfiles = (prev.social_profiles?.filter((value: BrandSocialProfile) => value.platform !== platform)) || [];

            if (profile_url && profile_url.trim().length > 0) {
                newSocialProfiles.push({ platform: platform, profile_url: profile_url });
            }


            return {
                ...prev,
                social_profiles: newSocialProfiles.length > 0 ? [...newSocialProfiles] : null
            } as BrandForm;
        });
    }

    const checkFields = () => {
        if (!brandLogo) {
            setPfpUploaded('Please upload your logo')
            setPreventSubmition(true)
            return
        }

        if (preventSubmition) {
            setPfpUploaded('')
            setPreventSubmition(false)
        }
    }

    const findProfileUrl = (platformType: SocialPlatformType) => {
        return (form.social_profiles && form.social_profiles.find(value => value.platform === platformType)?.profile_url) || '';
    };

    const handleCompanyType = (type: string) => {
        if (companyType === type) {
            setCompanyType('');
            return
        }
        setCompanyType(type);
    }

    useEffect(() => {
        setForm((prev) => ({
            ...prev,
            brand_type: companyType
        }));

    }, [companyType])

    useEffect(()=>{
        if(!form.social_profiles && includeSocial && !brandLogo){
            setPreventSubmition(true);
        }else{
            setPreventSubmition(false);
        }
    }, [form.social_profiles, brandLogo]);

    return (
        <Container>
            {children}

            {loading ? (
                <ImageLoading>
                    <FormMedia
                        name={'logo'}
                        image={brandLogo}
                        showUpload={true}
                        onChange={handleChangeLogo}
                        flexColumn
                    />
                    <div className="imageLoadingDiv">
                        <Loader status={'loading'} />
                    </div>
                </ImageLoading>
            ) : (
                <FormMedia
                    name={'logo'}
                    image={brandLogo}
                    showUpload={true}
                    onChange={handleChangeLogo}
                    flexColumn
                />
            )}


            <Form onSubmit={handleSubmit}>
                <FormInput
                    type={'text'}
                    name={'name'}
                    label={'Brand name'}
                    placeholder={'Enter your brand name'}
                    value={form.name}
                    error={errors?.name}
                    required={true}
                    onChange={handleChange}
                />

                <FormTextarea
                    name={'description'}
                    label={'Brand description'}
                    placeholder={'Type more about your brand...'}
                    value={form.description}
                    error={errors?.description}
                    limit={240}
                    required={true}
                    onChange={handleChange}
                />

                <FormInput
                    type={'text'}
                    name={'website'}
                    placeholder={'www.website.com'}
                    label={'Website'}
                    value={form.website}
                    error={errors?.website}
                    required={true}
                    onChange={handleChange}
                    maxLength={100}
                />

                <FormLabel required>
                    Are you a brand or an agency?
                </FormLabel>

                <AgencyQuestionRow>
                    <AgencyOption onClick={() => handleCompanyType('brand')}>
                        <p>I am a brand</p>
                        <FormCheck checked={companyType === 'brand'} />
                    </AgencyOption>

                    <AgencyOption onClick={() => handleCompanyType('agency')}>
                        <p>I am an agency</p>
                        <FormCheck checked={companyType === 'agency'} />
                    </AgencyOption>
                </AgencyQuestionRow>

                {!includeSocial ? (
                    <FormInput
                        type={'hidden'}
                        name={'affiliate_code'}
                        label={'Do you have an affiliate/referral code?'}
                        placeholder={'Please specify'}
                        value={form.affiliate_code as string}
                        error={errors?.affiliate_code}
                        required={false}
                        onChange={handleChange}
                    />
                ) : null}


                <FormMultiSelect
                    name={'tags'}
                    label={'What niches best describe your brand?'}
                    placeholder={'What niches best describe your brand?'}
                    error={errors?.tags}
                    options={filteredTags}
                    selected={form.tags.map((value) => {
                        return { id: value.id as number, name: value.name }
                    })}
                    limit={maxTags}
                    required={true}
                    onChange={handleChange}
                />

                {includeSocial && Mapping.SupportedSocialPlatforms.map(({ id, name }) => (
                    <FormGroup key={id}>
                        <FormLabel>
                            {name}
                        </FormLabel>

                        <FormInline>
                            {Mapping.Social(id)}

                            <SocialFormInput
                                type={'text'}
                                name={id}
                                label={''}
                                placeholder={Mapping.SocialPlaceholder(id)}
                                value={findProfileUrl(id)}
                                onChange={handleChangeSocial}
                            />
                        </FormInline>
                    </FormGroup>
                ))}

                <StyledButton
                    onClick={() => checkFields()}
                    loading={loading}
                    disabled={preventSubmition}
                >
                    {buttonLabel || 'Next'}
                </StyledButton>
                {pfpUploaded && <Error>{pfpUploaded}</Error>}
            </Form>
        </Container>
    );
}

const AgencyQuestionRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 56px;
    transform: translateY(-16px);
    margin-bottom: -16px;

    span {
        display: inline-block;
        margin-right: 8px;
    }

    @media (max-width:900px) and (min-width:0px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: -8px;
        margin-top: 8px;
    }
`

const AgencyOption = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;

    p {
        height: 12px;
        margin-right: 8px !important;
        transform: translateY(-4px);
        color: var(--grey-9);
    }

    @media (max-width:900px) and (min-width:0px) {
        margin-right: 0 !important;
        justify-content: flex-start;
        margin-bottom: 8px;
    }

`

const StyledButton = styled(Button)`
    margin-top: 8px;
`;

const SocialFormInput = styled(FormInput)`
    flex: 1;
`;

const FormInline = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    column-gap: 8px;

    svg {
        width: 32px;
        height: 32px;
    }
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-top: 16px;

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px !important;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ImageLoading = styled.div`
    position: relative;

    .imageLoadingDiv {
        position: absolute;
        height: 124px;
        width: 100%;
        background: rgba(255,255,255,.4);
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const Error = styled.p`
    color: #ff647c;
`
