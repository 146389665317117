import {WaveListItem} from "../WaveListItem";
import {Helpers} from "../../../../utils";
import styled from "styled-components";
import subscriptionService, {PlanType} from "../../../../services/subscriptionService";
import {Button} from "../../../components";
import {Actions, useAppDispatch, useAppSelector} from "../../../../store";
import {useMemo} from "react";

interface Props {
    gift: GiftItem;
    brand: BrandProfile;
    items: Wave[];
    selectedTabId: string;
    selectedId?: string;
    loading?: boolean;
    searching: boolean;
}

const MatchOverlay = ({ gift, brand, items, selectedId, selectedTabId, loading, searching }: Props) => {

    const dispatcher = useAppDispatch();

    const {
        global: {
            settings
        }
    } = useAppSelector((state) => state);

    const isPro = useMemo(() => brand?.subscription && subscriptionService.isSubscriptionType(brand?.subscription.subscription, PlanType.PRO) && brand?.subscription.status !== 'trialing', [brand]);

    const getMatchCapHeader = () => {
        let subtitle = '';
        if (!brand) {return}
        const releasedWaveCount = releasedWavesCount();
        if (brand.subscriptionv2?.status === 'trialing' && releasedWaveCount === 0 && !gift.waves_by_status?.PENDING) {
            subtitle = 'Your #gift is live, and influencers are browsing, so sit tight! Applicants will start to roll in soon.\n';
        } else if (brand.subscriptionv2?.status === 'trialing' && releasedWaveCount < 15 && gift.waves_by_status?.PENDING) {
            subtitle = 'New influencers are rolling in daily. Check back tomorrow for more.\n';
        } else if (brand.subscriptionv2?.status === 'trialing' && releasedWaveCount >= 15) {
            subtitle = 'You can see a maximum of 15 applicants on your trial';
        } else {
            subtitle = `You have ${gift?.waves_by_status.PENDING} more matches`
        }

        return (
            <>
                <h1 style={{textAlign: 'center'}}>{subtitle}</h1>
            </>
        )
    }
    const getMatchCapLimitMessage = () => {
        let subtitle = '';
        let plan;
        if (!brand) {return}
        if (!brand.subscription) {
            subtitle = `Match with up to 30 creators with #gifted Basic`
        } else {
            plan = brand.subscription.subscription;
        }

        if (!plan) {
            subtitle = ``
        } else {
            if (brand?.subscriptionv2?.status === 'trialing') {
                subtitle = `15 creators are the maximum you can be matched with, on your 7-day free trial. You can match with up to 30 on the Basic plan, 90 on the Grow plan, or get unlimited matches with the Pro plan.`
            } else if (plan.name === 'Gifted Pro') {
                subtitle = 'Match with unlimited creators with #gifted Pro.'
            } else if (plan.name === 'Gifted Grow') {   
                subtitle = `You’ve matched with your maximum of ${subscriptionService.features(plan).get('influencers.matches.limit')} creators on your Grow subscription. You can match with unlimited creators with Pro.`
            } else if (plan.name === 'Gifted Starter') {
                subtitle = `You’ve matched with your maximum of ${subscriptionService.features(plan).get('influencers.matches.limit')} creators on your Basic subscription. You can match with up to 90 on the Grow plan or get unlimited matches with Pro.`
            }
        }

        return (
            <>
                <h2>{subtitle}</h2>
            </>
        )
    }

    const releasedWavesCount = () => {
        let totalReleased = 0;
        totalReleased += gift.waves_by_status?.SUBMITTED ?? 0;
        totalReleased += gift.waves_by_status?.SHORTLISTED ?? 0;
        totalReleased += gift.waves_by_status?.ACCEPTED ?? 0;
        totalReleased += gift.waves_by_status?.COMPLETED ?? 0;
        totalReleased += gift.waves_by_status?.REJECTED ?? 0;
        return totalReleased;
    };

    const getMatchCapMessage = () => {
        let upgradePlan;
        let cta = ''

        if (brand?.subscriptionv2?.status === 'trialing') {
            cta = 'Finish Trial and access all matches'
        } else if (!brand?.subscription) {
            cta = 'Upgrade to Basic'
        } else {
            upgradePlan = subscriptionService.findUpgradePlan(subscriptionService.findPlanTypeForSubscription(brand.subscription));
        }

        if (!upgradePlan) {
            cta = 'Upgrade to Basic'
        } else {
            const newPlan = subscriptionService.findPlanDefinition(upgradePlan)
            if (newPlan.shortName === 'Pro') {
                cta = 'Upgrade to Pro'
            }

            if (newPlan.shortName === 'Grow') {
                cta = 'Upgrade to Grow'
            }

            if (newPlan.shortName === 'Starter') {
                cta = 'Upgrade to Basic'
            }
        }

        return (
            <>
                <Button theme="aqua" onClick={() => dispatcher(Actions.App.setShowPaymentModal(true, !subscriptionService.isSubscribed(brand?.subscription)))}>{cta}</Button>
            </>
        )
    }

    const showMatchOverlay = useMemo(() => {
        if (searching) {
            return false;
        }

        if (!settings?.features.subscriptions || selectedTabId !== 'new' || isPro) {
            return false;
        }

        if (gift) {
            if (gift.waves_by_status.PENDING) {
                return true;
            }
        }

        return false;

    }, [settings, brand, gift, selectedTabId, items, isPro])

    return (
        <>
            {showMatchOverlay && (
                <MatchCapOverlay>
                    {dummy.map((item, i) => (
                        <WaveListItem
                            {...item}
                            key={item?.uid}
                            customClass={'wave-list dummy-wave-list'}
                            index={i}
                            id={item.influencer?.uid}
                            name={item.influencer.name}
                            headshotImage={item.influencer.headshot_image}
                            matchPercentage={item.influencer_match_per}
                            badgeCounts={{}}
                            waveStatus={item.status}
                            selected={item?.uid === selectedId}
                            withdrawn={!!item?.influencer_withdrawn_at}
                            loading={loading}
                        />
                    ))}
                    <ExtraOverlay></ExtraOverlay>
                    <MatchOverflowContainer>
                        {/* @ts-ignore */}
                        {getMatchCapHeader()}
                        {getMatchCapLimitMessage()}
                        {getMatchCapMessage()}
                    </MatchOverflowContainer>
                </MatchCapOverlay>
            )}
        </>
    )
};

export default MatchOverlay;

const dummy = Array(5).fill('').map(() => ({
    uid: Helpers.generateId(),
    influencer: {
        uid: Helpers.generateId(),
        name: '',
        headshot_image: null,
        bio: '',
    },
    latest_bubble: {
        created_at: ''
    },
    bio: '',
    tabId: '',
    last_message_at: '',
    influencer_match_per: 100,
    influencer_withdrawn_at: false,
    status: '',
    status_updated_at: '',
}));

const MatchCapOverlay = styled.div`
    position: relative;
    width: 764px;
    height: 260px;
    display: flex;
    flex-direction: column;
    z-index: 5;
    background: linear-gradient(to top, rgb(255 255 255 / 90%), rgb(255 255 255));
    backdrop-filter: blur(10px);
    padding-bottom: 40px;

    .dummy-wave-list {
        margin-bottom: 16px;
    }

    h2 {
        text-align: center;
    }

    @media (max-width:900px) and (min-width:0px) {
        width: 100%;
        height: 100%;
    }
`

const ExtraOverlay = styled.div`
    position: absolute;
    width: 772px;
    height: 445px;
    display: flex;
    flex-direction: column;
    z-index: 5;
    background: linear-gradient(to bottom, rgb(255 255 255 / 90%), rgb(255 255 255 / 98%));
    border-bottom-left-radius: 12px;
    padding-bottom: 40px;

    .dummy-wave-list {
        margin-bottom: 16px;
    }

    @media (max-width:900px) and (min-width:0px) {
        width: 100%;
        height: 100%;
    }

`

const MatchOverflowContainer = styled.div`
  width: 637px;
  padding: 40px;
  background: white;
  box-shadow: 0 6px 12px rgba(41, 43, 50, 0.12);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 70px;
  z-index: 10;

  h1 {
    font-size: 32px;
    line-height: 40px;
    color: #262161;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    color: #262161;
    font-weight: 600;
    margin: 12px 0;
    margin-bottom: 20px;
    padding: 0;
  }

  @media (max-width:900px) and (min-width:0px) {
    width: 100%;
    text-align: center;
    top: 0;
  }   

`
