import styled from 'styled-components';
import { useState } from 'react';
import { Actions } from 'store';
import { API } from 'api';

// Define survey types
export type SurveyType = 'nps' | 'satisfaction';

interface FeedbackSurveyProps {
    type?: SurveyType;
    brandUid: string;
    representativeUid: string;
}

// Custom SVG Heart components
const HeartFilledIcon = () => (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M14.9642 2.62237L14.5138 3.11931L14.0634 2.62096C12.5077 0.930937 10.4119 -0.0109876 8.23177 9.67304e-05C6.05167 0.011181 3.96373 0.974378 2.42216 2.68015C0.880585 4.38592 0.0101048 6.69625 8.74192e-05 9.10855C-0.00992993 11.5208 0.841326 13.8399 2.36867 15.5613L2.81905 16.0597L14.5138 29L26.2085 16.0597L26.6589 15.5613C28.1741 13.8372 29.015 11.5225 28.9998 9.11768C28.9846 6.71289 28.1144 4.41138 26.5775 2.71098C25.0406 1.01059 22.9606 0.0479921 20.7873 0.0314081C18.6139 0.0148241 16.5221 0.945585 14.9642 2.62237Z" 
            fill="#FF0509"
        />
    </svg>
);

const HeartOutlineIcon = () => (
    <svg width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M28.24 3.5C26.65 1.90582 24.5423 0.932814 22.2976 0.756759C20.0529 0.580704 17.8192 1.2132 16 2.54C14.0915 1.12046 11.716 0.476771 9.35193 0.73856C6.98782 1.00035 4.81071 2.14817 3.25901 3.95088C1.70731 5.75359 0.896281 8.07728 0.989249 10.454C1.08222 12.8308 2.07227 15.084 3.76005 16.76L13.075 26.09C13.8551 26.8577 14.9056 27.2879 16 27.2879C17.0945 27.2879 18.145 26.8577 18.925 26.09L28.24 16.76C29.9914 14.9979 30.9744 12.6144 30.9744 10.13C30.9744 7.64559 29.9914 5.2621 28.24 3.5ZM26.125 14.69L16.81 24.005C16.704 24.112 16.5779 24.197 16.4389 24.255C16.2998 24.313 16.1507 24.3428 16 24.3428C15.8494 24.3428 15.7003 24.313 15.5612 24.255C15.4222 24.197 15.296 24.112 15.19 24.005L5.87505 14.645C4.69868 13.4425 4.03996 11.8272 4.03996 10.145C4.03996 8.46281 4.69868 6.84749 5.87505 5.645C7.07378 4.46149 8.69051 3.79786 10.375 3.79786C12.0596 3.79786 13.6763 4.46149 14.875 5.645C15.0145 5.7856 15.1804 5.89719 15.3632 5.97334C15.546 6.04949 15.742 6.0887 15.94 6.0887C16.1381 6.0887 16.3341 6.04949 16.5169 5.97334C16.6997 5.89719 16.8656 5.7856 17.005 5.645C18.2038 4.46149 19.8205 3.79786 21.505 3.79786C23.1896 3.79786 24.8063 4.46149 26.005 5.645C27.1976 6.83173 27.8779 8.43829 27.9004 10.1205C27.9228 11.8028 27.2855 13.4269 26.125 14.645V14.69Z" 
            fill="#FF0509"
            stroke="#FF0509"
            strokeWidth="0.5"
        />
    </svg>
);

export function FeedbackSurvey({ type = 'satisfaction', brandUid, representativeUid }: FeedbackSurveyProps) {
    const [selectedRating, setSelectedRating] = useState<number | null>(null);
    const [hoverRating, setHoverRating] = useState<number | null>(null);
    const [submitted, setSubmitted] = useState(false);

    const handleRatingClick = (rating: number) => {
        setSelectedRating(rating);
        // Here you could add additional logic to submit the rating or show a follow-up question
        API.Profile.sendSurveyResult(brandUid, representativeUid, type, rating).then(() => {
            setSubmitted(true);
            //@ts-ignore
            window.submittedNps = true;
        }).catch((error: any) => {
            console.error(error);
        });
    };

    const renderNPSSurvey = () => (
        <>
            <Question>
                How likely are you to recommend #gifted to a friend or colleague?
            </Question>
            <RatingContainer>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
                    <RatingButton 
                        key={rating}
                        $selected={selectedRating === rating}
                        onClick={() => handleRatingClick(rating)}
                    >
                        {rating}
                    </RatingButton>
                ))}
            </RatingContainer>
            <ScaleLabels>
                <ScaleLabel>Not likely</ScaleLabel>
                <ScaleLabel>Very likely</ScaleLabel>
            </ScaleLabels>
        </>
    );

    const renderSatisfactionSurvey = () => (
        <>
            <Question>
                How satisfied are you with #gifted?
            </Question>
            <HeartRatingContainer>
                {[1, 2, 3, 4, 5].map((rating) => (
                    <HeartButton
                        key={rating}
                        $selected={selectedRating !== null && selectedRating >= rating}
                        onClick={() => handleRatingClick(rating)}
                        onMouseEnter={() => setHoverRating(rating)}
                        onMouseLeave={() => setHoverRating(null)}
                    >
                        {(selectedRating !== null && selectedRating >= rating) || (hoverRating !== null && hoverRating >= rating) ? (
                            <HeartFilledIcon />
                        ) : (
                            <HeartOutlineIcon />
                        )}
                    </HeartButton>
                ))}
            </HeartRatingContainer>
        </>
    );

    return (
        <Container style={{ display: submitted ? 'none' : 'flex' }}>
            <SurveyCard style={{ maxWidth: type === 'nps' ? '580px' : '410px' }}>
                {type === 'nps' && renderNPSSurvey()}
                {type === 'satisfaction' && renderSatisfactionSurvey()}
            </SurveyCard>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0px;
    z-index: 1;
`;

const SurveyCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 580px;
    padding: 24px;
    padding-top: 0px;
    padding-bottom: 12px;
    border-radius: 24px;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 70px;
`;

const Question = styled.h3`
    font-size: 20px;
    font-weight: 600;
    color: var(--grey-9, #333);
    margin-bottom: 10px;
    text-align: left;
    line-height: 24px;
`;

const RatingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    flex-wrap: wrap;

    @media (max-width: 600px) {
        justify-content: center;
        gap: 8px;
    }
`;

const RatingButton = styled.button<{ $selected: boolean }>`
    width: 48px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
    background-color: ${props => props.$selected ? 'var(--blue, #3B82F6)' : 'var(--grey-2, #F5F5F5)'};
    color: ${props => props.$selected ? 'white' : 'var(--grey-9, #333)'};

    &:hover {
        background-color: ${props => props.$selected ? 'var(--blue, #3B82F6)' : 'var(--grey-3, #ECECEC)'};
        transform: translateY(-2px);
    }
`;

const ScaleLabels = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
`;

const ScaleLabel = styled.span`
    font-size: 14px;
    color: var(--grey-6, #666);
`;

// Heart rating styles
const HeartRatingContainer = styled.div`
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-bottom: 8px;
`;

const HeartButton = styled.button<{ $selected: boolean }>`
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    transition: transform 0.2s ease;
    width: 31px;
    height: 31px;
    
    &:hover {
        transform: scale(1.1);
    }
`;
